import { useState, useEffect, useRef } from "react";

const speechText = [
  ["Battle start", 0],
  ["Ground-Razing Particle Beam", 16.9],
  ["The Art Of Darkness (L/R)", 27.1],
  ["The Art Of Darkness", 36.8],
  ["Devouring Dark", 47.5],
  ["Obscure Woods", 63.1],
];

// const speechText = [
//   ["0 seconds", 0],
//   ["2 seconds", 2],
//   ["4 seconds", 4],
//   ["6 seconds", 6],
//   ["8 seconds", 8],
//   ["10 seconds", 10],
//   ["12 seconds", 12],
//   ["14 seconds", 14],
//   ["16 seconds", 16],
//   ["18 seconds", 18],
//   ["20 seconds", 20],
// ];

export default function Speech() {
  const [voices, setVoices] = useState([]);
  const [speechQueue, setSpeechQueue] = useState();
  let voiceRef = useRef();
  let delayRef = useRef();

  useEffect(() => {
    setVoices(speechSynthesis.getVoices());
  }, []);

  //   function speak(text) {
  //     const mySpeech = new SpeechSynthesisUtterance(text);
  //     mySpeech.voice = voices[voiceRef.current.value];
  //     speechSynthesis.speak(mySpeech);
  //   }

  function speak() {
    let speechDelay = delayRef.current.value;
    let delayTime;
    let queueList = [];
    for (let x = 0; x < speechText.length; x++) {
      const mySpeech = new SpeechSynthesisUtterance(speechText[x][0]);
      mySpeech.voice = voices[voiceRef.current.value];
      delayTime = (speechText[x][1] - speechDelay) * 1000;
      if (delayTime < 0) delayTime = 0;
      queueList.push(
        setTimeout(() => {
          speechSynthesis.speak(mySpeech);
        }, delayTime)
      );
    }
    setSpeechQueue(queueList);
  }

  function clearQueue() {
    if (speechQueue) {
      speechQueue.forEach((entry) => {
        clearTimeout(entry);
      });
      setSpeechQueue();
    }
  }

  return (
    <>
      <select ref={voiceRef}>
        {voices.map((voice, index) => (
          <option value={index}>{voice.name}</option>
        ))}
      </select>
      <div>
        <label>Delay</label>
        <select ref={delayRef}>
          <option value={0}>Immediate</option>
          <option value={1}>1 second</option>
          <option value={2}>2 seconds</option>
          <option value={3}>3 seconds</option>
          <option value={4}>4 seconds</option>
          <option value={5}>5 seconds</option>
          <option value={6}>6 seconds</option>
          <option value={7}>7 seconds</option>
          <option value={8}>8 seconds</option>
          <option value={9}>9 seconds</option>
          <option value={10}>10 seconds</option>
        </select>
        <button onClick={clearQueue} disabled={!speechQueue}>
          Stop
        </button>
      </div>
      <button onClick={() => speak()} disabled={speechQueue}>
        Battle Start
      </button>
    </>
  );
}

import { useState, useEffect, useRef } from "react";
import config from "../config";

const headerIcons = [
  "headerIconCRP",
  "headerIconBSM",
  "headerIconARM",
  "headerIconGSM",
  "headerIconLTW",
  "headerIconWVR",
  "headerIconALC",
  "headerIconCUL",
];

const jobNames = [
  "Carpenter",
  "Blacksmith",
  "Armorer",
  "Goldsmith",
  "Leatherworker",
  "Weaver",
  "Alchemist",
  "Culinarian",
];

const listIcons = [
  "listIconCRP",
  "listIconBSM",
  "listIconARM",
  "listIconGSM",
  "listIconLTW",
  "listIconWVR",
  "listIconALC",
  "listIconCUL",
];

const othersCategoryList = [
  ["housing", "Housing"],
  ["collectable", "Collectables"],
  ["customdelivery", "Custom Deliveries"],
  ["tribal", "Tribal Quests"],
  ["class", "Class Quests"],
  ["sidequest", "Sidequests"],
  ["other", "Others"],
];

export default function CraftingLog() {
  const searchRef = useRef();
  const [logCRP, setLogCRP] = useState();
  const [logBSM, setLogBSM] = useState();
  const [logARM, setLogARM] = useState();
  const [logGSM, setLogGSM] = useState();
  const [logLTW, setLogLTW] = useState();
  const [logWVR, setLogWVR] = useState();
  const [logALC, setLogALC] = useState();
  const [logCUL, setLogCUL] = useState();
  const [currentJobBrowsing, setCurrentJobBrowsing] = useState(0);
  const [searchPanel, setSearchPanel] = useState(false);
  const [recipeTab, setRecipeTab] = useState("level");
  const [selectedSection, setSelectedSection] = useState(["level", 0]);
  const [selectedRecipeIndex, setSelectedRecipeIndex] = useState();
  const [selectedRecipeData, setSelectedRecipeData] = useState();
  const [openSections, setOpenSections] = useState({
    housing: false,
    collectable: false,
    customdelivery: false,
    tribal: false,
    class: false,
    sidequest: false,
    other: false,
  });
  const [page, setPage] = useState(1);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedSearchResultIndex, setSelectedSearchResultIndex] = useState();
  const [holdCtrl, setHoldCtrl] = useState(false);
  const [contextMenuOpen, setContextMenuOpen] = useState([false, 0]);
  const [favorites, setFavorites] = useState();

  const logList = [
    [logCRP, setLogCRP],
    [logBSM, setLogBSM],
    [logARM, setLogARM],
    [logGSM, setLogGSM],
    [logLTW, setLogLTW],
    [logWVR, setLogWVR],
    [logALC, setLogALC],
    [logCUL, setLogCUL],
  ];

  useEffect(() => {
    document.title = "XIV Library | Crafting Log";
    let storedFavorites = localStorage.getItem("craftingLogFavorites");
    if (!storedFavorites) {
      localStorage.setItem("craftingLogFavorites", []);
      setFavorites([]);
    } else {
      setFavorites(JSON.parse(storedFavorites));
    }
  }, []);

  useEffect(() => {
    if (favorites) {
      localStorage.setItem("craftingLogFavorites", JSON.stringify(favorites));
    }
  }, [favorites]);

  useEffect(() => {
    async function getLog() {
      if (!logList[currentJobBrowsing][0]) {
        const response = await fetch(
          `${config.BACKEND_URL}/log/crafting/${currentJobBrowsing}`
        );
        if (response.ok) {
          logList[currentJobBrowsing][1](await response.json());
        } else {
          alert(await response.json());
        }
      }
    }
    getLog();
    setOpenSections({
      housing: false,
      collectable: false,
      customdelivery: false,
      tribal: false,
      class: false,
      sidequest: false,
      other: false,
    });
  }, [currentJobBrowsing]);

  useEffect(() => {
    async function getRecipe() {
      const response = await fetch(
        `https://xivapi.com/recipe/${
          selectedSection[0] === "favorites"
            ? favorites[selectedRecipeIndex].ID
            : logList[currentJobBrowsing][0][selectedSection[0]][
                selectedSection[1]
              ].entries[selectedRecipeIndex].ID
        }`
      );
      if (response.ok) {
        setSelectedRecipeData(await response.json());
      } else {
        alert(await response.json());
      }
    }
    if (selectedRecipeIndex !== undefined) {
      if (selectedSection[0] === "favorites") {
        if (!favorites[selectedRecipeIndex]) {
          return;
        }
      } else if (
        !logList[currentJobBrowsing][0][selectedSection[0]][selectedSection[1]]
          .entries[selectedRecipeIndex]
      ) {
        return;
      }
      getRecipe();
    }
  }, [selectedRecipeIndex]);

  useEffect(() => {
    document.getAnimations().forEach((animation) => {
      animation.startTime = 0;
    });
  }, [selectedRecipeData]);

  useEffect(() => {
    document.addEventListener("keydown", (event) => {
      if (event.key === "Control") {
        setHoldCtrl(true);
      }
    });

    return document.removeEventListener("keydown", (event) => {
      if (event.key === "Control") {
        setHoldCtrl(true);
      }
    });
  }, []);

  useEffect(() => {
    document.addEventListener("keyup", (event) => {
      if (event.key === "Control") {
        setHoldCtrl(false);
      }
    });

    return document.removeEventListener("keyup", (event) => {
      if (event.key === "Control") {
        setHoldCtrl(false);
      }
    });
  }, []);

  async function doSearch(query) {
    if (query === "") {
      return;
    }
    for (let x = 0; x < searchResults.length; x++) {
      if (searchResults[x].Search === query) {
        let temp = JSON.parse(JSON.stringify(searchResults));
        let entry = temp.splice(x, 1)[0];
        temp.unshift(entry);
        setSearchResults(temp);
        setSelectedSearchResultIndex(0);
        return;
      }
    }
    const response = await fetch(
      `https://xivapi.com/search?indexes=recipe&string=${query}&columns=ID,Icon,Name,RecipeLevelTable.ClassJobLevel,RecipeLevelTable.Stars,ItemResult.Rarity,CraftTypeTargetID`
    );
    if (response.ok) {
      let results = await response.json();
      results.Results.sort((a, b) => {
        if (a.CraftTypeTargetID > b.CraftTypeTargetID) {
          return 1;
        } else if (a.CraftTypeTargetID < b.CraftTypeTargetID) {
          return -1;
        } else if (
          a.RecipeLevelTable.ClassJobLevel > b.RecipeLevelTable.ClassJobLevel
        ) {
          return 1;
        } else if (
          a.RecipeLevelTable.ClassJobLevel < b.RecipeLevelTable.ClassJobLevel
        ) {
          return -1;
        } else if (a.ID > b.ID) {
          return 1;
        } else {
          return -1;
        }
      });
      if (searchResults.length === 9) {
        let temp = JSON.parse(JSON.stringify(searchResults));
        temp.pop();
        temp.unshift({ Results: results.Results, Search: query });
        setSearchResults(temp);
      } else {
        setSearchResults([
          { Results: results.Results, Search: query },
          ...searchResults,
        ]);
      }
      setSelectedSearchResultIndex(0);
    } else {
      alert(await response.json());
    }
  }

  return (
    <>
      <div className="menu">
        {contextMenuOpen[0] ? (
          <div
            className="contextBlocker"
            onClick={() => {
              setContextMenuOpen([false, 0]);
            }}
          />
        ) : (
          ""
        )}
        <div className="menuContainer">
          <div>
            <p className="craftingLogName">Crafting Log</p>
            <div className="closeButton" />
            <p className="craftingLogJobName">
              {currentJobBrowsing === "default"
                ? "Carpenter"
                : jobNames[currentJobBrowsing]}
            </p>
            <div
              className={
                currentJobBrowsing === "default"
                  ? "headerIconCRP"
                  : headerIcons[currentJobBrowsing]
              }
            />
            <div className="levelText">LEVEL 90</div>
          </div>
          <div className="craftingLogJobList">
            {listIcons.map((job, index) => (
              <div
                className="craftingLogJob"
                key={index}
                onClick={() => {
                  setCurrentJobBrowsing(index);
                  setSearchPanel(false);
                  setSelectedRecipeIndex();
                }}
              >
                <div className="listIconBackground" />
                <div className={job} />
                {currentJobBrowsing === index && !searchPanel && (
                  <div className="listIconBorder" />
                )}
              </div>
            ))}
            <div
              className="craftingLogJob"
              onClick={() => {
                setSearchPanel(true);
                setSelectedRecipeIndex();
              }}
            >
              <div className="listIconBackground" />
              <div className="listIconSearch" />
              {searchPanel && <div className="listIconBorder" />}
            </div>
          </div>
          <div className="craftingLogDividerHorizontal" />
          <div className="craftingLogMainBody">
            <div className="craftingLogLeftBar">
              <div className="recipeSearchContainer">
                <p className="recipeSearchName">Recipe Search</p>
                <input
                  className="recipeSearchInput"
                  type="search"
                  autoComplete="off"
                  ref={searchRef}
                  onClick={() => {
                    setSearchPanel(true);
                  }}
                  onKeyUp={(event) => {
                    if (event.key === "Enter") doSearch(event.target.value);
                  }}
                />
              </div>
              {!searchPanel ? (
                <>
                  <div className="craftingLogExtrasContainer">
                    <p className="craftingLogSectionName">Extras</p>
                    <div className="craftingLogExtrasList">
                      <div
                        className="craftingLogSectionSlot"
                        onClick={() => {
                          setSelectedSection(["favorites", ""]);
                          setSelectedRecipeIndex(0);
                        }}
                      >
                        <label className="craftingLogSectionHover" />
                        {selectedSection[0] === "favorites" && (
                          <div className="craftingLogSectionActive" />
                        )}
                        <div className="craftingLogSectionText">Favorites</div>
                      </div>
                    </div>
                  </div>
                  <div className="craftingLogRecipesContainer">
                    <div className="craftingLogSectionName">Recipe Level</div>
                    <div
                      className="craftingLogTabsContainer"
                      onClick={() => {
                        if (recipeTab === "level") {
                          setRecipeTab("other");
                        } else if (recipeTab === "other") {
                          setRecipeTab("master");
                        } else {
                          setRecipeTab("level");
                        }
                      }}
                    >
                      <div className="craftingLogTabsInnerContainer">
                        <div className="craftingLogTabs">
                          {recipeTab === "level" ? (
                            <>
                              <div className="craftingLogTabsLeftBackgroundActive" />
                              <div className="craftingLogLevelIconActive" />
                            </>
                          ) : (
                            <>
                              <div className="craftingLogTabsLeftBackground" />
                              <div className="craftingLogLevelIcon" />
                            </>
                          )}
                        </div>
                        <div className="craftingLogTabs">
                          {recipeTab === "other" ? (
                            <>
                              <div className="craftingLogTabsMiddleBackgroundActive" />
                              <div className="craftingLogOthersIconActive" />
                            </>
                          ) : (
                            <>
                              <div className="craftingLogTabsMiddleBackground" />
                              <div className="craftingLogOthersIcon" />
                            </>
                          )}
                        </div>
                        <div className="craftingLogTabs">
                          {recipeTab === "master" ? (
                            <>
                              <div className="craftingLogTabsRightBackgroundActive" />
                              <div className="craftingLogMasterIconActive" />
                            </>
                          ) : (
                            <>
                              <div className="craftingLogTabsRightBackground" />
                              <div className="craftingLogMasterIcon" />
                            </>
                          )}
                        </div>
                      </div>
                      <div className="craftingLogTabsBorder" />
                    </div>
                  </div>
                  <div className="craftingLogCategoriesContainer">
                    {currentJobBrowsing !== "default" &&
                      logList[currentJobBrowsing][0] &&
                      (recipeTab === "level"
                        ? logList[currentJobBrowsing][0].level.map(
                            (data, index) => (
                              <div
                                key={index}
                                className="craftingLogSectionSlot"
                                onClick={() => {
                                  setSelectedSection(["level", index]);
                                  setSelectedRecipeIndex();
                                }}
                              >
                                <label className="craftingLogSectionHover" />
                                {selectedSection[0] === "level" &&
                                  selectedSection[1] === index && (
                                    <div className="craftingLogSectionActive" />
                                  )}
                                <div className="craftingLogSectionText">
                                  {data.section}
                                </div>
                              </div>
                            )
                          )
                        : recipeTab === "other"
                        ? othersCategoryList.map((category, index) => (
                            <div key={index}>
                              <div
                                className="craftingLogCategorySlot"
                                key={index}
                                onClick={() => {
                                  setOpenSections({
                                    ...openSections,
                                    [category[0]]: !openSections[category[0]],
                                  });
                                }}
                              >
                                <div className="craftingLogCategoryBackground" />
                                {openSections[category[0]] ? (
                                  <div className="craftingLogCategoryButtonOpen" />
                                ) : (
                                  <div className="craftingLogCategoryButtonClose" />
                                )}
                                <div className="craftingLogCategoryText">
                                  {category[1]}
                                </div>
                              </div>
                              {openSections[category[0]] &&
                                logList[currentJobBrowsing][0][category[0]].map(
                                  (section, index) => (
                                    <div
                                      className="craftingLogSectionSlot"
                                      key={index}
                                      onClick={() => {
                                        setSelectedSection([
                                          category[0],
                                          index,
                                        ]);
                                        setSelectedRecipeIndex();
                                      }}
                                    >
                                      <label className="craftingLogSectionHover" />
                                      {selectedSection[0] === category[0] &&
                                        selectedSection[1] === index && (
                                          <div className="craftingLogSectionActive" />
                                        )}
                                      <div className="craftingLogSectionText">
                                        {section.section}
                                      </div>
                                    </div>
                                  )
                                )}
                            </div>
                          ))
                        : logList[currentJobBrowsing][0].master.map(
                            (data, index) => (
                              <div
                                className="craftingLogSectionSlot"
                                key={index}
                                onClick={() => {
                                  setSelectedSection(["master", index]);
                                  setSelectedRecipeIndex();
                                }}
                              >
                                <label className="craftingLogSectionHover" />
                                {selectedSection[0] === "master" &&
                                  selectedSection[1] === index && (
                                    <div className="craftingLogSectionActive" />
                                  )}
                                <div className="craftingLogSectionText">
                                  {data.section}
                                </div>
                              </div>
                            )
                          ))}
                  </div>
                </>
              ) : (
                <SearchHistory
                  searchResults={searchResults}
                  selectedSearchResultIndex={selectedSearchResultIndex}
                  setSelectedSearchResultIndex={setSelectedSearchResultIndex}
                  setContextMenuOpen={setContextMenuOpen}
                />
              )}
            </div>

            <div className="craftingLogDividerVertical" />
            <div className="craftingLogMiddleBar">
              {!searchPanel ? (
                selectedSection[0] === "favorites" ? (
                  <>
                    {favorites.length ? (
                      <>
                        <div className="craftingLogRecipeList">
                          {favorites
                            .slice(
                              page * 100 - 100,
                              Math.min(page * 100, favorites.length)
                            )
                            .map((entry, index) => (
                              <RecipeEntry
                                key={index}
                                entry={entry}
                                index={index}
                                page={page}
                                selectedRecipeIndex={selectedRecipeIndex}
                                setSelectedRecipeIndex={setSelectedRecipeIndex}
                                contextMenuOpen={contextMenuOpen}
                                setContextMenuOpen={setContextMenuOpen}
                                favorites={favorites}
                                setFavorites={setFavorites}
                              />
                            ))}
                        </div>
                        <div className="craftingLogPageScrollSection">
                          <>
                            <div className="craftingLogPageArrows">
                              {page > 1 ? (
                                <div
                                  className="craftingLogPageLeftActive"
                                  onClick={() => {
                                    setPage(page - 1);
                                  }}
                                />
                              ) : (
                                <div className="craftingLogPageLeft" />
                              )}
                              {page * 100 < favorites.length ? (
                                <div
                                  className="craftingLogPageRightActive"
                                  onClick={() => {
                                    setPage(page + 1);
                                  }}
                                />
                              ) : (
                                <div className="craftingLogPageRight" />
                              )}
                            </div>
                            <div className="craftingLogPageScrollCount">
                              {page * 100 - 99}-
                              {Math.min(page * 100, favorites.length)}
                            </div>
                          </>
                        </div>
                      </>
                    ) : (
                      <div className="craftingLogRecipeListEmpty">
                        No recipes to display
                      </div>
                    )}

                    <div className="craftingLogDividerHorizontal" />
                    <div className="craftingLogCharacteristics">
                      {favorites.length ? (
                        <>
                          <div className="craftingLogCharacteristicsTitle">
                            Characteristics
                          </div>
                          <div className="craftingLogCharacteristicsList">
                            {selectedRecipeData && (
                              <Characteristics
                                data={selectedRecipeData}
                                selectedRecipeIndex={selectedRecipeIndex}
                              />
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    {logList[currentJobBrowsing][0] &&
                    logList[currentJobBrowsing][0][selectedSection[0]][
                      selectedSection[1]
                    ].entries.length !== 0 ? (
                      <>
                        <div className="craftingLogRecipeList">
                          {logList[currentJobBrowsing][0][selectedSection[0]][
                            selectedSection[1]
                          ].entries
                            .slice(
                              page * 100 - 100,
                              Math.min(
                                page * 100,
                                logList[currentJobBrowsing][0][
                                  selectedSection[0]
                                ][selectedSection[1]].entries.length
                              )
                            )
                            .map((entry, index) => (
                              <RecipeEntry
                                key={index}
                                entry={entry}
                                index={index}
                                page={page}
                                selectedRecipeIndex={selectedRecipeIndex}
                                setSelectedRecipeIndex={setSelectedRecipeIndex}
                                contextMenuOpen={contextMenuOpen}
                                setContextMenuOpen={setContextMenuOpen}
                                favorites={favorites}
                                setFavorites={setFavorites}
                              />
                            ))}
                        </div>
                        <div className="craftingLogPageScrollSection">
                          <div className="craftingLogPageArrows">
                            {page > 1 ? (
                              <div
                                className="craftingLogPageLeftActive"
                                onClick={() => {
                                  setPage(page - 1);
                                }}
                              />
                            ) : (
                              <div className="craftingLogPageLeft" />
                            )}
                            {page * 100 <
                            logList[currentJobBrowsing][0][selectedSection[0]][
                              selectedSection[1]
                            ].entries.length ? (
                              <div
                                className="craftingLogPageRightActive"
                                onClick={() => {
                                  setPage(page + 1);
                                }}
                              />
                            ) : (
                              <div className="craftingLogPageRight" />
                            )}
                          </div>
                          <div className="craftingLogPageScrollCount">
                            {page * 100 - 99}-
                            {Math.min(
                              page * 100,
                              logList[currentJobBrowsing][0][
                                selectedSection[0]
                              ][selectedSection[1]].entries.length
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="craftingLogRecipeListEmpty">
                        No recipes to display
                      </div>
                    )}
                    <div className="craftingLogDividerHorizontal" />
                    <div className="craftingLogCharacteristics">
                      {selectedRecipeIndex !== undefined && (
                        <div className="craftingLogCharacteristicsTitle">
                          Characteristics
                        </div>
                      )}

                      <div className="craftingLogCharacteristicsList">
                        {selectedRecipeData && (
                          <Characteristics
                            data={selectedRecipeData}
                            selectedRecipeIndex={selectedRecipeIndex}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )
              ) : (
                <SearchResults
                  searchResults={searchResults}
                  page={page}
                  setPage={setPage}
                  selectedRecipeData={selectedRecipeData}
                  setSelectedRecipeData={setSelectedRecipeData}
                  selectedSearchResultIndex={selectedSearchResultIndex}
                  contextMenuOpen={contextMenuOpen}
                  setContextMenuOpen={setContextMenuOpen}
                  favorites={favorites}
                  setFavorites={setFavorites}
                />
              )}
            </div>
            <div className="craftingLogDividerVertical" />
            <div className="craftingLogRightBar">
              {selectedRecipeData && (
                <Overview
                  key={selectedRecipeData.Name}
                  data={selectedRecipeData}
                  holdCtrl={holdCtrl}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function RecipeEntry({
  entry,
  index,
  page,
  selectedRecipeIndex,
  setSelectedRecipeIndex,
  contextMenuOpen,
  setContextMenuOpen,
  favorites,
  setFavorites,
}) {
  const [contextMenuCoords, setContextMenuCoords] = useState([0, 0]);

  return (
    <>
      <div
        className="craftingLogRecipeEntry"
        onClick={() => {
          setSelectedRecipeIndex(page * 100 - 100 + index);
        }}
        onContextMenu={(event) => {
          event.preventDefault();
          setContextMenuOpen([true, page * 100 - 100 + index]);
          setContextMenuCoords([event.pageX, event.pageY]);
        }}
      >
        <div className="craftingLogRecipeEntryImage">
          <img src={`https://xivapi.com${entry.Icon}`} />
          <div className="craftingLogRecipeEntryImageFilter" />
        </div>
        <div className="craftingLogRecipeEntryInfo">
          <label className="craftingLogRecipeEntryHover" />
          {selectedRecipeIndex === page * 100 - 100 + index && (
            <label className="craftingLogRecipeEntryActive" />
          )}
          <div
            className={`craftingLogRecipeEntryName ${
              entry.Rarity === 2
                ? "rarityGreen"
                : entry.Rarity === 3
                ? "rarityBlue"
                : entry.Rarity === 4
                ? "rarityPurple"
                : entry.Rarity === 7 && "rarityPink"
            }`}
          >
            {entry.Name}
          </div>
          <div className="craftingLogRecipeEntryStats">
            <div className="craftingLogRecipeEntryLevel">Lv.{entry.Level}</div>
            <div className="craftingLogRecipeEntryStars">
              {"★".repeat(entry.Stars)}
            </div>
          </div>
        </div>
        {favorites.some((favEntry) => favEntry.ID === entry.ID) ? (
          <div className="favoritePin" />
        ) : (
          ""
        )}
      </div>
      {contextMenuOpen[0] && contextMenuOpen[1] === page * 100 - 100 + index ? (
        <div
          className="contextMenu"
          style={{
            left: contextMenuCoords[0],
            top: contextMenuCoords[1],
          }}
        >
          <div className="contextMenuBackground" />
          <div className="contextMenuContent">
            <div
              className="contextMenuEntry"
              onClick={() => {
                navigator.clipboard.writeText(entry.Name);
                setContextMenuOpen([false, 0]);
              }}
            >
              <label className="contextMenuHover" />
              <p>Copy Item Name</p>
            </div>
            {favorites.some((favEntry) => favEntry.ID === entry.ID) ? (
              <div
                className="contextMenuEntry"
                onClick={() => {
                  let favIndex = favorites.findIndex(
                    (favEntry) => favEntry.ID === entry.ID
                  );
                  let newFavorites = JSON.parse(JSON.stringify(favorites));
                  newFavorites.splice(favIndex, 1);
                  setFavorites(newFavorites);
                  setContextMenuOpen([false, 0]);
                }}
              >
                <label className="contextMenuHover" />
                <p>Remove from Favorites</p>
              </div>
            ) : (
              <div
                className="contextMenuEntry"
                onClick={() => {
                  setFavorites([entry, ...favorites]);
                  setContextMenuOpen([false, 0]);
                }}
              >
                <label className="contextMenuHover" />
                <p>Add to Favorites</p>
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

function Characteristics({ data, selectedRecipeIndex }) {
  return (
    <>
      {selectedRecipeIndex !== undefined ? (
        <>
          {data.IsSpecializationRequired === 1 && (
            <div className="craftingLogCharacteristicsEntry">
              Specialist Recipe
            </div>
          )}
          {data.CanQuickSynth === 0 && (
            <div className="craftingLogCharacteristicsEntry">
              Quick Synthesis Unavailable
            </div>
          )}
          {data.RecipeLevelTable.SuggestedCraftsmanship !== 0 &&
            data.RequiredCraftsmanship === 0 && (
              <div className="craftingLogCharacteristicsEntry">
                Craftsmanship Recommended:{" "}
                {data.RecipeLevelTable.SuggestedCraftsmanship}
              </div>
            )}
          {data.RequiredCraftsmanship !== 0 && (
            <div className="craftingLogCharacteristicsEntry">
              Craftsmanship Required: {data.RequiredCraftsmanship}
            </div>
          )}
          {data.RequiredControl !== 0 && (
            <div className="craftingLogCharacteristicsEntry">
              Control Required: {data.RequiredControl}
            </div>
          )}
          {data.RequiredQuality !== 0 && (
            <div className="craftingLogCharacteristicsEntry">
              Quality Required for Synthesis: {data.RequiredQuality}
            </div>
          )}

          {data.QuickSynthCraftsmanship !== 0 && (
            <div className="craftingLogCharacteristicsEntry">
              Quick Synthesis Craftsmanship Required:{" "}
              {data.QuickSynthCraftsmanship}
            </div>
          )}
          {data.QuickSynthControl !== 0 && (
            <div className="craftingLogCharacteristicsEntry">
              Quick Synthesis Control Required: {data.QuickSynthControl}
            </div>
          )}

          {data.IsExpert === 1 && (
            <div className="craftingLogCharacteristicsEntry">Expert Recipe</div>
          )}
          {data.CanHq === 0 && (
            <div className="craftingLogCharacteristicsEntry">
              HQ Uncraftable
            </div>
          )}
          {data.ExpRewarded === 0 && (
            <div className="craftingLogCharacteristicsEntry">
              No EXP Granted
            </div>
          )}
          {data.ItemResult.AlwaysCollectable === 1 && (
            <div className="craftingLogCharacteristicsEntry">
              Always Synthesized as Collectable
            </div>
          )}
          {data.StatusRequired !== null && (
            <div className="craftingLogCharacteristicsEntry">
              Status Required: {data.StatusRequired.Name}
            </div>
          )}
          {data.ItemRequired !== null && (
            <div className="craftingLogCharacteristicsEntry">
              Item Required: {data.ItemRequired.Name}
            </div>
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
}

function Overview({ data, holdCtrl }) {
  const materialList = [
    ["AmountIngredient0", "ItemIngredient0", "ItemIngredientRecipe0"],
    ["AmountIngredient1", "ItemIngredient1", "ItemIngredientRecipe1"],
    ["AmountIngredient2", "ItemIngredient2", "ItemIngredientRecipe2"],
    ["AmountIngredient3", "ItemIngredient3", "ItemIngredientRecipe3"],
    ["AmountIngredient4", "ItemIngredient4", "ItemIngredientRecipe4"],
    ["AmountIngredient5", "ItemIngredient5", "ItemIngredientRecipe5"],
  ];

  const [materialQuantity, setMaterialQuantity] = useState([
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
  ]);

  const [currentQuality, setCurrentQuality] = useState(0);

  const [itemLevelFactor, setItemLevelFactor] = useState(1);

  useEffect(() => {
    let factor = 0;
    for (let x = 0; x < materialList.length; x++) {
      if (data[materialList[x][0]] && data[materialList[x][2]]) {
        factor += data[materialList[x][0]] * data[materialList[x][1]].LevelItem;
      }
    }
    setItemLevelFactor(factor);
  }, []);

  useEffect(() => {
    let quality = 0;
    for (let x = 0; x < materialQuantity.length; x++) {
      if (materialQuantity[x][1] && data[materialList[x][2]]) {
        quality += materialQuantity[x][1] * data[materialList[x][1]].LevelItem;
      }
    }
    quality =
      (quality / itemLevelFactor) *
      ((data.RecipeLevelTable.Quality * data.MaterialQualityFactor) / 100);
    if (isNaN(quality)) {
      quality = 0;
    }
    setCurrentQuality(parseInt(quality));
  }, [materialQuantity]);

  function addMaterial(quality, index) {
    let tempMaterialQuantity = JSON.parse(JSON.stringify(materialQuantity));
    let totalQty = data[materialList[index][0]];
    if (quality === "nq") {
      if (tempMaterialQuantity[index][0] === totalQty) {
        return;
      } else {
        tempMaterialQuantity[index][0] += 1;
        if (
          tempMaterialQuantity[index][0] + tempMaterialQuantity[index][1] >
          totalQty
        ) {
          tempMaterialQuantity[index][1] -= 1;
        }
      }
    } else if (quality === "hq") {
      if (tempMaterialQuantity[index][1] === totalQty) {
        return;
      } else {
        tempMaterialQuantity[index][1] += 1;
        if (
          tempMaterialQuantity[index][0] + tempMaterialQuantity[index][1] >
          totalQty
        ) {
          tempMaterialQuantity[index][0] -= 1;
        }
      }
    }
    setMaterialQuantity(tempMaterialQuantity);
  }

  return (
    <>
      <div className="craftingLogRecipeOverview">
        <div className="craftingLogRecipeOverviewContainer">
          <div className="craftingLogRecipeOverviewHeader">
            <RecipeOverviewItem data={data} holdCtrl={holdCtrl} />
            <p
              className={
                data.ItemResult.Rarity === 2
                  ? "rarityGreen"
                  : data.ItemResult.Rarity === 3
                  ? "rarityBlue"
                  : data.ItemResult.Rarity === 4
                  ? "rarityPurple"
                  : data.ItemResult.Rarity === 7
                  ? "rarityPink"
                  : ""
              }
            >
              {data.Name}
            </p>
          </div>
          <div className="craftingLogRecipeOverviewStats">
            <div
              className="craftingLogRecipeOverviewStatsSlot fontDark"
              style={{ textAlign: "left" }}
            >
              Difficulty
            </div>
            <div
              className="craftingLogRecipeOverviewStatsSlot fontLight"
              style={{ width: "23%" }}
            >
              {data.RecipeLevelTable.Difficulty}
            </div>
            <div
              className="craftingLogRecipeOverviewStatsSlot fontDark"
              style={{ width: "32%" }}
            >
              Quality
            </div>
            <div
              className="craftingLogRecipeOverviewStatsSlot fontVariableLarge"
              style={{ width: "25%" }}
            >
              {currentQuality}
            </div>
          </div>
          <div
            className="craftingLogDividerHorizontal"
            style={{
              width: "22%",
              float: "right",
              marginTop: "-2px",
              marginRight: "11px",
            }}
          />
          <div className="craftingLogRecipeOverviewStats">
            <div
              className="craftingLogRecipeOverviewStatsSlot fontDark"
              style={{ textAlign: "left" }}
            >
              Durability
            </div>
            <div
              className="craftingLogRecipeOverviewStatsSlot fontLight"
              style={{ width: "23%" }}
            >
              {data.RecipeLevelTable.Durability}
            </div>
            <div
              className="craftingLogRecipeOverviewStatsSlot fontDark"
              style={{ width: "32%" }}
            >
              (Max {data.MaterialQualityFactor}%)
            </div>
            <div
              className="craftingLogRecipeOverviewStatsSlot fontDark"
              style={{ width: "25%" }}
            >
              {data.RecipeLevelTable.Quality}
            </div>
          </div>
        </div>
      </div>
      <div className="craftingLogRecipeInventory">
        <div
          className="craftingLogRecipeInventorySlot fontDark"
          style={{ textAlign: "left", width: "20%" }}
        >
          Craftable
        </div>
        <div
          className="craftingLogRecipeInventorySlot fontLight"
          style={{ width: "23%" }}
        >
          99
        </div>
        <div
          className="craftingLogRecipeInventorySlot fontDark"
          style={{ width: "35%" }}
        >
          Bag
        </div>
        <div
          className="craftingLogRecipeInventorySlot fontLight"
          style={{ width: "22%" }}
        >
          0
        </div>
      </div>
      <div
        className="craftingLogRecipeInventory"
        style={{
          justifyContent: "flex-end",
          marginTop: "-3px",
        }}
      >
        <img className="hqIcon" />
        <div
          style={{
            justifyContent: "flex-end",
          }}
        >
          0
        </div>
      </div>
      <div className="craftingLogMaterialContainer">
        <div className="craftingLogCrystal">
          <RecipeCrystalItem
            amount={data.AmountIngredient8}
            item={data.ItemIngredient8}
          />
          <div style={{ width: "27px" }} />
          <RecipeCrystalItem
            amount={data.AmountIngredient9}
            item={data.ItemIngredient9}
          />
        </div>
        <div style={{ marginTop: "20px", marginBottom: "2px" }}>
          <div className="craftingLogMaterialHeaderMaterials fontDark">
            Materials
          </div>
          <div className="craftingLogMaterialHeader fontDark">NQ</div>
          <div className="craftingLogMaterialHeader fontDark">HQ</div>
        </div>
        {materialList.map((entry, index) => (
          <RecipeMaterialItem
            key={index}
            index={index}
            data={data}
            entry={entry}
            addMaterial={addMaterial}
            materialQuantity={materialQuantity}
            holdCtrl={holdCtrl}
          />
        ))}
      </div>
      <div className="craftingLogSynthesisContainer">
        <div className="craftingLogTrialSynthesis">
          <div className="craftingLogTrialSynthesisBackground" />
          <div className="craftingLogTrialSynthesisIcon" />
        </div>
        <div style={{ width: "30px" }} />
        <div className="craftingLogQuickSynthesis">
          <div className="craftingLogQuickSynthesisBackground" />
          <p>Quick Synthesis</p>
        </div>
        <div className="craftingLogSynthesis">
          <div className="craftingLogSynthesisBackground" />
          <p>Synthesize</p>
        </div>
      </div>
    </>
  );
}

function SearchHistory({
  searchResults,
  selectedSearchResultIndex,
  setSelectedSearchResultIndex,
}) {
  return (
    <>
      <div className="craftingLogExtrasContainer">
        <div className="craftingLogSectionName">History</div>
      </div>
      <div className="craftingLogTabsContainer">
        <div className="craftingLogTabsInnerContainer">
          <div className="craftingLogTabs" style={{ width: "50%" }}>
            <div className="craftingLogTabsLeftBackgroundActive" />
            <div className="craftingLogSearchQueryIconActive" />
          </div>
          <div className="craftingLogTabs" style={{ width: "50%" }}>
            <div className="craftingLogTabsRightBackground" />
            <div className="craftingLogSearchOriginalIcon" />
          </div>
        </div>
        <div className="craftingLogTabsBorder" />
      </div>
      <div className="craftingLogCategoriesContainer">
        {searchResults.length !== 0 ? (
          searchResults.map((result, index) => (
            <div
              className="craftingLogSectionSlot"
              key={index}
              onClick={() => {
                setSelectedSearchResultIndex(index);
              }}
            >
              <label className="craftingLogSectionHover" />
              {selectedSearchResultIndex === index && (
                <div className="craftingLogSectionActive" />
              )}
              <div className="craftingLogSectionText">{result.Search}</div>
            </div>
          ))
        ) : (
          <p
            style={{
              textAlign: "center",
              color: "#a0a0a0",
              marginTop: "150px",
            }}
          >
            Nothing to display.
          </p>
        )}
      </div>
    </>
  );
}

function SearchResults({
  searchResults,
  page,
  setPage,
  selectedRecipeData,
  setSelectedRecipeData,
  selectedSearchResultIndex,
  contextMenuOpen,
  setContextMenuOpen,
  favorites,
  setFavorites,
}) {
  const [selectedRecipeIndex, setSelectedRecipeIndex] = useState();

  const jobDescriptions = [
    "Woodworking",
    "Smithing",
    "Armorcraft",
    "Goldsmithing",
    "Leatherworking",
    "Clothcraft",
    "Alchemy",
    "Cooking",
  ];

  useEffect(() => {
    async function getRecipe() {
      const response = await fetch(
        `https://xivapi.com/recipe/${searchResults[selectedSearchResultIndex].Results[selectedRecipeIndex].ID}`
      );
      if (response.ok) {
        let results = await response.json();
        setSelectedRecipeData(results);
      } else {
        alert(await response.json());
      }
    }
    if (selectedRecipeIndex !== undefined) {
      getRecipe();
    }
  }, [selectedRecipeIndex]);

  return (
    <>
      {searchResults.length !== 0 ? (
        <>
          <div className="craftingLogRecipeList">
            {searchResults[selectedSearchResultIndex].Results.slice(
              page * 100 - 100,
              Math.min(
                page * 100,
                searchResults[selectedSearchResultIndex].Results.length
              )
            ).map((entry, index) => (
              <div key={index}>
                {index === 0 ||
                entry.CraftTypeTargetID !==
                  searchResults[selectedSearchResultIndex].Results[index - 1]
                    .CraftTypeTargetID ? (
                  <div className="searchResultHeader">
                    ❖ {jobDescriptions[entry.CraftTypeTargetID]}
                  </div>
                ) : (
                  ""
                )}
                <SearchRecipeEntry
                  key={index}
                  entry={entry}
                  index={index}
                  page={page}
                  selectedRecipeIndex={selectedRecipeIndex}
                  setSelectedRecipeIndex={setSelectedRecipeIndex}
                  contextMenuOpen={contextMenuOpen}
                  setContextMenuOpen={setContextMenuOpen}
                  favorites={favorites}
                  setFavorites={setFavorites}
                />
              </div>
            ))}
          </div>
          <div className="craftingLogPageScrollSection">
            <div className="craftingLogPageArrows">
              {page > 1 ? (
                <div
                  className="craftingLogPageLeftActive"
                  onClick={() => {
                    setPage(page - 1);
                  }}
                />
              ) : (
                <div className="craftingLogPageLeft" />
              )}
              {page * 100 <
              searchResults[selectedSearchResultIndex].Results.length ? (
                <div
                  className="craftingLogPageRightActive"
                  onClick={() => {
                    setPage(page + 1);
                  }}
                />
              ) : (
                <div className="craftingLogPageRight" />
              )}
            </div>
            <div className="craftingLogPageScrollCount">
              {page * 100 - 99}-
              {Math.min(
                page * 100,
                searchResults[selectedSearchResultIndex].Results.length
              )}
            </div>
          </div>
          <div className="craftingLogDividerHorizontal" />
          <div className="craftingLogCharacteristics">
            <div className="craftingLogCharacteristicsTitle">
              Characteristics
            </div>
            <div className="craftingLogCharacteristicsList">
              {selectedRecipeData && (
                <Characteristics
                  data={selectedRecipeData}
                  selectedRecipeIndex={selectedRecipeIndex}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            style={{ height: "405px", display: "flex", alignItems: "center" }}
          >
            <p
              style={{
                textAlign: "center",
                fontSize: "17px",
                color: "#a0a0a0",
              }}
            >
              Type in the full or partial name of an item and press Enter to
              commence the search.
            </p>
          </div>
          <div className="craftingLogDividerHorizontal" />
          <div className="craftingLogCharacteristics"></div>
        </>
      )}
    </>
  );
}

function SearchRecipeEntry({
  entry,
  index,
  page,
  selectedRecipeIndex,
  setSelectedRecipeIndex,
  contextMenuOpen,
  setContextMenuOpen,
  favorites,
  setFavorites,
}) {
  const [contextMenuCoords, setContextMenuCoords] = useState([]);

  return (
    <div
      className="craftingLogRecipeEntry"
      onClick={() => {
        setSelectedRecipeIndex(page * 100 - 100 + index);
      }}
      onContextMenu={(event) => {
        event.preventDefault();
        setContextMenuOpen([true, page * 100 - 100 + index]);
        setContextMenuCoords([event.pageX, event.pageY]);
      }}
    >
      <div className="craftingLogRecipeEntryImage">
        <img src={`https://xivapi.com${entry.Icon}`} />
        <div className="craftingLogRecipeEntryImageFilter" />
      </div>
      <div className="craftingLogRecipeEntryInfo">
        <label className="craftingLogRecipeEntryHover" />
        {selectedRecipeIndex === page * 100 - 100 + index && (
          <label className="craftingLogRecipeEntryActive" />
        )}
        <div
          className={`craftingLogRecipeEntryName ${
            entry.ItemResult.Rarity === 2
              ? "rarityGreen"
              : entry.ItemResult.Rarity === 3
              ? "rarityBlue"
              : entry.ItemResult.Rarity === 4
              ? "rarityPurple"
              : entry.ItemResult.Rarity === 7 && "rarityPink"
          }`}
        >
          {entry.Name}
        </div>
        <div className="craftingLogRecipeEntryStats">
          <div className="craftingLogRecipeEntryLevel">
            Lv.{entry.RecipeLevelTable.ClassJobLevel}
          </div>
          <div className="craftingLogRecipeEntryStars">
            {"★".repeat(entry.RecipeLevelTable.Stars)}
          </div>
        </div>
      </div>
      {favorites.some((favEntry) => favEntry.ID === entry.ID) ? (
        <div className="favoritePin" />
      ) : (
        ""
      )}
      {contextMenuOpen[0] && contextMenuOpen[1] === page * 100 - 100 + index ? (
        <div
          className="contextMenu"
          style={{
            left: contextMenuCoords[0],
            top: contextMenuCoords[1],
          }}
        >
          <div className="contextMenuBackground" />
          <div className="contextMenuContent">
            <div
              className="contextMenuEntry"
              onClick={() => {
                navigator.clipboard.writeText(entry.Name);
                setContextMenuOpen([false, 0]);
              }}
            >
              <label className="contextMenuHover" />
              <p>Copy Item Name</p>
            </div>
            {favorites.some((favEntry) => favEntry.ID === entry.ID) ? (
              <div
                className="contextMenuEntry"
                onClick={() => {
                  let favIndex = favorites.findIndex(
                    (favEntry) => favEntry.ID === entry.ID
                  );
                  let newFavorites = JSON.parse(JSON.stringify(favorites));
                  newFavorites.splice(favIndex, 1);
                  setFavorites(newFavorites);
                  setContextMenuOpen([false, 0]);
                }}
              >
                <label className="contextMenuHover" />
                <p>Remove from Favorites</p>
              </div>
            ) : (
              <div
                className="contextMenuEntry"
                onClick={() => {
                  setFavorites([entry, ...favorites]);
                  setContextMenuOpen([false, 0]);
                }}
              >
                <label className="contextMenuHover" />
                <p>Add to Favorites</p>
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

function RecipeOverviewItem({ data, holdCtrl }) {
  const [mouseover, setMouseover] = useState(false);

  return (
    <div
      className="craftingLogRecipeOverviewImageContainer"
      onMouseEnter={() => setMouseover(true)}
      onMouseLeave={() => setMouseover(false)}
    >
      <img src={`https://xivapi.com${data.Icon}`} />
      <div className="craftingLogRecipeOverviewImageFilter" />
      {data.AmountResult !== 1 && <p>{data.AmountResult}</p>}
      {mouseover && (
        <ItemInfoModal
          data={data.ItemResult}
          holdCtrl={holdCtrl}
          amountResult={data.AmountResult}
        />
      )}
      {/* <ItemInfoModal
        data={data.ItemResult}
        holdCtrl={holdCtrl}
        amountResult={data.amountResult}
      /> */}
    </div>
  );
}

function RecipeCrystalItem({ amount, item }) {
  const [mouseover, setMouseover] = useState(false);

  return (
    <div className="craftingLogMaterialSlot">
      <div className="craftingLogMaterialQuantity">
        <p>{amount ? amount : ""}</p>
      </div>
      <div
        className="craftingLogMaterialImage"
        onMouseEnter={() => setMouseover(true)}
        onMouseLeave={() => setMouseover(false)}
      >
        {item && (
          <>
            <img src={`https://xivapi.com${item.Icon}`} />
          </>
        )}
        <div className="craftingLogMaterialImageBackground" />
        {item && <p>999</p>}
        {mouseover && amount ? <CrystalInfoModal data={item} /> : ""}
      </div>
    </div>
  );
}

function RecipeMaterialItem({
  data,
  index,
  entry,
  addMaterial,
  materialQuantity,
  holdCtrl,
}) {
  const [mouseover, setMouseover] = useState(false);

  return (
    <div style={{ display: "flex" }}>
      <div className="craftingLogMaterialSlot">
        <div
          className="craftingLogMaterialQuantity"
          style={{ alignItems: "center", marginTop: 0 }}
        >
          <p>{data[entry[0]] ? data[entry[0]] : ""}</p>
        </div>
        <div
          className="craftingLogMaterialImage"
          onMouseEnter={() => setMouseover(true)}
          onMouseLeave={() => setMouseover(false)}
        >
          {data[entry[0]] ? (
            <>
              <img src={`https://xivapi.com${data[entry[1]].Icon}`} />
              <div className="craftingLogMaterialImageBackground" />
            </>
          ) : (
            <div className="craftingLogMaterialImageBackgroundFill" />
          )}
          {mouseover && data[entry[0]] ? (
            <ItemInfoModal data={data[entry[1]]} holdCtrl={holdCtrl} />
          ) : (
            ""
          )}
        </div>
      </div>
      {data[entry[0]] ? (
        <>
          <div className="craftingLogMaterialQuantitySelectContainer">
            <div
              className="craftingLogMaterialQuantitySelectRow"
              style={{ height: "19px" }}
            >
              <div
                className="craftingLogMaterialQuantitySelectLarge"
                onClick={() => {
                  addMaterial("nq", index);
                }}
              >
                <label className="craftingLogMaterialQuantitySelectBackground" />
                <div className="craftingLogMaterialQuantitySelectLargeText">
                  {materialQuantity[index][0]}
                </div>
              </div>
              <div
                className="craftingLogMaterialQuantitySelectLarge"
                onClick={() => {
                  addMaterial("hq", index);
                }}
              >
                <label className="craftingLogMaterialQuantitySelectBackground" />
                <div className="craftingLogMaterialQuantitySelectLargeText">
                  {materialQuantity[index][1]}
                </div>
              </div>
              {data[entry[2]] && <div className="qualityUpArrow" />}
            </div>
            <div
              className="craftingLogDividerHorizontal"
              style={{ margin: 0 }}
            />
            <div className="craftingLogMaterialQuantitySelectRow">
              <div className="craftingLogMaterialQuantitySelectSmall">
                <p>99</p>
              </div>
              <div className="craftingLogMaterialQuantitySelectSmall">
                <p>99</p>
              </div>
            </div>
          </div>
          <div className="craftingLogMaterialQuantityDivider" />
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="craftingLogMaterialName">{data[entry[1]].Name}</div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

function ItemInfoModal({ data, holdCtrl, amountResult }) {
  const [itemData, setItemData] = useState();

  function PotionLayout() {
    return (
      <div className="itemInfoModalExtraStats">
        <div className="itemInfoModalExtraStatsEntry">
          <div className="itemInfoModalExtraStatsEntryTitle">Recast</div>
          <div className="itemInfoModalExtraStatsEntryText">
            {itemData.CooldownS / 60 >= 1 &&
              `${parseInt(itemData.CooldownS / 60)}m`}
            {itemData.CooldownS % 60 !== 0 &&
              `${parseInt(itemData.CooldownS % 60)}s`}
          </div>
          <div className="itemInfoModalExtraStatsEntryHighlight" />
        </div>
      </div>
    );
  }

  function FoodLayout() {
    return (
      <div className="itemInfoModalLevel">
        <p>ITEM LEVEL {itemData.LevelItem}</p>
      </div>
    );
  }

  function EquipLayout() {
    function Weapon() {
      return (
        <>
          <div className="itemInfoModalExtraStatsEntry">
            <div className="itemInfoModalExtraStatsEntryTitle">
              {itemData.DamageMag >= itemData.DamagePhys
                ? "Magic Damage"
                : "Physical Damage"}
            </div>
            <div className="itemInfoModalExtraStatsEntryText">
              {Math.max(itemData.DamageMag, itemData.DamagePhys)}
            </div>
            <div className="itemInfoModalExtraStatsEntryHighlight" />
          </div>
          <div className="itemInfoModalExtraStatsEntry">
            <div className="itemInfoModalExtraStatsEntryTitle">Auto-attack</div>
            <div className="itemInfoModalExtraStatsEntryText">
              {(
                (itemData.DelayMs / 1000 / 3) *
                Math.max(itemData.DamageMag, itemData.DamagePhys)
              ).toFixed(2)}
            </div>
            <div className="itemInfoModalExtraStatsEntryHighlight" />
          </div>
          <div className="itemInfoModalExtraStatsEntry">
            <div className="itemInfoModalExtraStatsEntryTitle">Delay</div>
            <div className="itemInfoModalExtraStatsEntryText">
              {(itemData.DelayMs / 1000).toFixed(2)}
            </div>
            <div className="itemInfoModalExtraStatsEntryHighlight" />
          </div>
        </>
      );
    }

    function Shield() {
      return (
        <>
          <div className="itemInfoModalExtraStatsEntry" />
          <div className="itemInfoModalExtraStatsEntry">
            <div className="itemInfoModalExtraStatsEntryTitle">
              Block Strength
            </div>
            <div className="itemInfoModalExtraStatsEntryText">
              {itemData.Block}
            </div>
            <div className="itemInfoModalExtraStatsEntryHighlight" />
          </div>
          <div className="itemInfoModalExtraStatsEntry">
            <div className="itemInfoModalExtraStatsEntryTitle">Block Rate</div>
            <div className="itemInfoModalExtraStatsEntryText">
              {itemData.BlockRate}
            </div>
            <div className="itemInfoModalExtraStatsEntryHighlight" />
          </div>
        </>
      );
    }

    function Armor() {
      return (
        <>
          <div className="itemInfoModalExtraStatsEntry" />
          <div className="itemInfoModalExtraStatsEntry">
            <div className="itemInfoModalExtraStatsEntryTitle">Defense</div>
            <div className="itemInfoModalExtraStatsEntryText">
              {itemData.DefensePhys}
            </div>
            <div className="itemInfoModalExtraStatsEntryHighlight" />
          </div>
          <div className="itemInfoModalExtraStatsEntry">
            <div className="itemInfoModalExtraStatsEntryTitle">
              Magic Defense
            </div>
            <div className="itemInfoModalExtraStatsEntryText">
              {itemData.DefenseMag}
            </div>
            <div className="itemInfoModalExtraStatsEntryHighlight" />
          </div>
        </>
      );
    }

    return (
      <>
        {itemData.EquipSlotCategoryTargetID === 2 &&
        itemData.Block === 0 &&
        itemData.BlockRate === 0 ? (
          ""
        ) : (
          <div className="itemInfoModalExtraStats">
            {itemData.EquipSlotCategoryTargetID === 1 ||
            itemData.EquipSlotCategoryTargetID === 13 ? (
              <Weapon />
            ) : itemData.EquipSlotCategoryTargetID === 2 ? (
              <Shield />
            ) : (
              <Armor />
            )}
          </div>
        )}
        <div
          className="itemInfoModalLevel"
          style={{ marginTop: "-5px", marginBottom: "5px" }}
        >
          <p>ITEM LEVEL {itemData.LevelItem}</p>
        </div>
        <div style={{ marginBottom: "5px" }}>
          {itemData.ClassJobCategory.Name}
        </div>
        <div style={{ color: "#00cc22" }}>Lv. {itemData.LevelEquip}</div>
        {itemData.Stats ? (
          <>
            <div style={{ marginTop: "10px", color: "#a0a0a0" }}>Bonuses</div>
            <div
              className="craftingLogDividerHorizontal"
              style={{
                position: "relative",
              }}
            />
            <div className="itemInfoModalBonusStats">
              {itemData.Stats.map((stat, index) => (
                <div
                  style={{
                    width: "50%",
                    textAlign: index % 2 === 0 ? "left" : "right",
                  }}
                >
                  {stat.Name} +{holdCtrl ? stat.HQ : stat.NQ}
                </div>
              ))}
            </div>
          </>
        ) : (
          ""
        )}
        {itemData.MateriaSlotCount ? (
          <>
            <div style={{ marginTop: "10px", color: "#a0a0a0" }}>Materia</div>
            <div
              className="craftingLogDividerHorizontal"
              style={{
                position: "relative",
              }}
            />
            {[...Array(itemData.MateriaSlotCount)].map((i) => (
              <div className="materia" />
            ))}
          </>
        ) : (
          ""
        )}
        <div style={{ marginTop: "10px", color: "#a0a0a0" }}>
          <div
            style={{
              display: "inline-block",
              position: "relative",
              height: "16px",
              width: "16px",
              marginRight: "2px",
            }}
          >
            <div
              className={listIcons[itemData.ClassJobRepair.DohDolJobIndex]}
              style={{
                transform: "scale(0.5, 0.5)",
                transformOrigin: "top left",
                top: "4px",
                left: "2px",
              }}
            />
            <div
              className="listIconBorder"
              style={{
                transform: "scale(0.5, 0.5)",
                transformOrigin: "top left",
                top: "3px",
                left: 0,
              }}
            />
          </div>
          <span className="fontGold">90</span> Crafting & Repairs
        </div>
        <div
          className="craftingLogDividerHorizontal"
          style={{
            position: "relative",
          }}
        />
        <div className="craftRepairGrid">
          <div className="fontGold">Condition</div>
          <div>100%</div>
          <div className="fontGold">Spiritbond</div>
          <div>0%</div>
          <div className="fontGold">Repair Level</div>
          <div>
            {itemData.ClassJobRepair.NameEnglish} Lv.{" "}
            {Math.max(itemData.LevelEquip - 10, 1)}
          </div>
          <div className="fontGold">Materials</div>
          <div>{itemData.ItemRepair.Item.Name}</div>
          <div className="fontGold">Quick Repairs</div>
          <div>
            0<span className="gilSymbol" />
          </div>
          {itemData.MateriaSlotCount ? (
            <>
              <div className="fontGold">Materia Melding</div>
              <div>
                {itemData.ClassJobRepair.ClassJobCategory.Name} Lv.{" "}
                {itemData.LevelEquip}
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </>
    );
  }

  useEffect(() => {
    async function getItemData() {
      const response = await fetch(`https://xivapi.com/item/${data.ID}`);
      if (response.ok) {
        let result = await response.json();
        result.Description = result.Description.replaceAll("\n\n", "\n");
        if (result.Bonuses) {
          let keys = Object.keys(result.Bonuses);
          let temp = [];
          for (let x of keys) {
            temp.push({
              ...result.Bonuses[x],
              Name: x.split(/(?=[A-Z])/).join(" "),
            });
          }
          result.Bonuses = temp;
        }
        if (result.Stats) {
          let keys = Object.keys(result.Stats);
          let temp = [];
          for (let x of keys) {
            temp.push({
              ...result.Stats[x],
              Name: x.split(/(?=[A-Z])/).join(" "),
            });
          }
          result.Stats = temp;
        }
        setItemData(result);
      } else {
        alert(await response.json());
      }
    }
    getItemData();
  }, []);

  return (
    <div className="itemInfoModal">
      <div className="itemInfoModalBackground" />
      <div className="itemInfoModalHeader">
        <div className="itemInfoModalImageContainer">
          <img src={`https://xivapi.com${data.Icon}`} />
          <div className="craftingLogRecipeOverviewImageFilter" />
          {holdCtrl ? <div className="hqItemOverlay" /> : ""}
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="itemInfoModalHeaderTitle">
            {data.Name}{" "}
            {holdCtrl ? (
              <div
                className="hqIcon"
                style={{
                  display: "inline-block",
                  position: "relative",
                  marginLeft: "5px",
                  width: "16px",
                  height: "16px",
                }}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="itemInfoModalDetails">
        <div>{data.ItemUICategory.Name}</div>
        <div
          style={{
            transformOrigin: "right",
            display: "flex",
            alignItems: "center",
          }}
        >
          {data.StackSize === 1 ? (
            ""
          ) : (
            <span style={{ marginRight: "3px" }}>
              {amountResult ? amountResult : 0}/{data.StackSize}
            </span>
          )}
          (Total: 0
          {data.CanBeHq ? (
            <span style={{ display: "flex", alignItems: "center" }}>
              /<span className="hqIcon" />0
            </span>
          ) : (
            ""
          )}
          )
        </div>
      </div>
      <div className="itemInfoModalDescription">
        {itemData &&
          (itemData.ItemSortCategoryTargetID === 5 ? (
            <EquipLayout />
          ) : itemData.ItemSortCategoryTargetID === 7 ? (
            <FoodLayout />
          ) : itemData.ItemSortCategoryTargetID === 6 ? (
            <PotionLayout />
          ) : itemData.AlwaysCollectable ? (
            ""
          ) : (
            <div
              className="craftingLogDividerHorizontal"
              style={{
                position: "relative",
                margin: "5px auto 3px auto",
              }}
            />
          ))}
        {itemData && (
          <>
            {itemData.AlwaysCollectable ? (
              <div>
                <div className="itemModalEffectsName">Collectable</div>
                <div
                  className="craftingLogDividerHorizontal"
                  style={{ marginBottom: "4px" }}
                />
                <div style={{ marginBottom: "5px" }}>
                  <span className="fontLight">Collectability</span>
                  <span style={{ marginLeft: "65px" }}>???</span>
                </div>
                <div
                  className="craftingLogDividerHorizontal"
                  style={{ marginBottom: "4px" }}
                />
              </div>
            ) : (
              ""
            )}

            {itemData.Bonuses && (
              <div>
                <div className="itemModalEffectsName">Effects</div>
                <div
                  className="craftingLogDividerHorizontal"
                  style={{ marginBottom: "4px" }}
                />
                {itemData.Bonuses.map((entry, index) => (
                  <div
                    key={index}
                    style={{ marginLeft: "4px", marginBottom: "2px" }}
                  >
                    {entry.Name} +{holdCtrl ? entry.ValueHQ : entry.Value}% (Max{" "}
                    {holdCtrl ? entry.MaxHQ : entry.Max})
                  </div>
                ))}
                <div
                  className="craftingLogDividerHorizontal"
                  style={{ marginTop: "10px", marginBottom: "4px" }}
                />
              </div>
            )}
            <div dangerouslySetInnerHTML={{ __html: itemData.Description }} />
            {itemData.FilterGroup === 12 && (
              <div style={{ marginTop: "17px" }}>Crafting Material</div>
            )}
            {itemData.Desynth === 0 ? (
              ""
            ) : itemData.ItemSortCategoryTargetID === 5 ? (
              <div>
                <span>Extractable: {itemData.MaterializeType ? "Y" : "N"}</span>
                <span style={{ marginLeft: "10px" }}>
                  Projectable: {itemData.IsGlamourous ? "Y" : "N"}
                </span>
                <span style={{ marginLeft: "10px" }}>
                  Desynthesizable: {itemData.LevelItem}.00
                </span>
              </div>
            ) : (
              <div style={{ marginTop: "5px" }}>
                Desynthesizable: {itemData.LevelItem}.00 (
                {itemData.ClassJobRepair.NameEnglish})
              </div>
            )}
            <div style={{ marginTop: "5px" }}>
              Shop Selling Price
              {holdCtrl && itemData.PriceMid === 99999 ? " (NQ)" : ""}:{" "}
              {itemData.PriceMid === 99999 ? (
                <span>
                  {itemData.PriceMid.toLocaleString("en-US")}
                  {<div className="gilSymbol" />}
                </span>
              ) : (
                "None"
              )}
            </div>
            <div style={{ marginTop: "8px" }}>
              Sells for{" "}
              {holdCtrl
                ? Math.ceil(itemData.PriceLow * 1.1).toLocaleString("en-US")
                : itemData.PriceLow.toLocaleString("en-US")}{" "}
              gil{" "}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function CrystalInfoModal({ data }) {
  return (
    <div className="itemInfoModal">
      <div className="itemInfoModalBackground" />
      <div className="itemInfoModalHeader">
        <div className="itemInfoModalImageContainer">
          <img src={`https://xivapi.com${data.Icon}`} />
        </div>
        <div>
          <p
            style={{ position: "relative", bottom: 0, right: 0, left: "10px" }}
          >
            {data.Name}
          </p>
        </div>
      </div>
      <div className="itemInfoModalDetails">
        <div>Crystal</div>
        <div
          style={{
            transformOrigin: "right",
            display: "flex",
            alignItems: "center",
          }}
        >
          999/9,999 (Total: 999)
        </div>
      </div>
      <div
        className="craftingLogDividerHorizontal"
        style={{
          position: "relative",
          width: "calc(100% - 30px)",
          margin: "5px auto -5px auto",
        }}
      />
      {data && (
        <div className="itemInfoModalDescription">
          <div dangerouslySetInnerHTML={{ __html: data.Description }} />
          <div style={{ marginTop: "17px" }}>Crafting Catalyst</div>
          <div style={{ marginTop: "5px" }}>Shop Selling Price: None</div>
          <div style={{ marginTop: "8px" }}>Unsellable</div>
        </div>
      )}
    </div>
  );
}

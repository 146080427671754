import "../App.css";
import "../CSS/deepdungeon.css";
import config from "../config";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";

const dungeonList = ["potd", "hoh", "eo"];

export default function DeepDungeon() {
  const navigate = useNavigate();
  const { dungeonid, dungeonfloorset } = useParams();
  const [dungeonType, setDungeonType] = useState();
  const [dungeonData, setDungeonData] = useState();
  const [selectedItem, setSelectedItem] = useState({ type: "", index: 0 });
  const [viewing, setViewing] = useState("floors");
  const [floorIndex, setFloorIndex] = useState(0);

  useEffect(() => {
    document.title = "XIV Library | Deep Dungeon Log";
    if (dungeonid && dungeonList.includes(dungeonid)) {
      setDungeonType(dungeonid);
    } else {
      setDungeonType("potd");
    }
    if (dungeonfloorset && !isNaN(dungeonfloorset)) {
      setFloorIndex(Math.floor((parseInt(dungeonfloorset) - 1) / 10));
    }
  }, []);

  useEffect(() => {
    async function getDungeonData() {
      const response = await fetch(
        `${config.BACKEND_URL}/log/deepdungeon/${dungeonType}`
      );
      if (response.ok) {
        let data = await response.json();
        setDungeonData(data);
        setSelectedItem({ type: "", index: 0 });
        if (
          isNaN(floorIndex) ||
          floorIndex >= data.floors.length ||
          floorIndex < 0
        ) {
          setFloorIndex(0);
        }
      } else {
        alert(await response.json());
      }
    }
    if (dungeonType) getDungeonData();
  }, [dungeonType]);

  function dungeonTypeLeft() {
    if (dungeonType === "potd") {
      setDungeonType("eo");
      navigate("/logs/deepdungeon/eo/1");
    } else if (dungeonType === "eo") {
      setDungeonType("hoh");
      navigate("/logs/deepdungeon/hoh/1");
    } else if (dungeonType === "hoh") {
      setDungeonType("potd");
      navigate("/logs/deepdungeon/potd/1");
    }
    setFloorIndex(0);
  }

  function dungeonTypeRight() {
    if (dungeonType === "potd") {
      setDungeonType("hoh");
      navigate("/logs/deepdungeon/hoh/1");
    } else if (dungeonType === "hoh") {
      setDungeonType("eo");
      navigate("/logs/deepdungeon/eo/1");
    } else if (dungeonType === "eo") {
      setDungeonType("potd");
      navigate("/logs/deepdungeon/potd/1");
    }
    setFloorIndex(0);
  }

  if (!dungeonType) {
    return;
  }

  return (
    <div className="deepdungeon">
      <div className="deepdungeonBackground" />
      <div className="deepdungeonContent">
        <div className="deepdungeonHeaderContainer">
          <img className="deepdungeonArrowLeft" onClick={dungeonTypeLeft} />
          <div className="deepdungeonLogo" />
          <img className="deepdungeonArrowRight" onClick={dungeonTypeRight} />
        </div>
        <div className="deepdungeonType">
          <img
            src={require(`../Assets/DeepDungeon/${dungeonType}_banner.png`)}
          />
        </div>
        <div className="menuDividerHorizontal" />
        {dungeonData && (
          <>
            {viewing === "items" && (
              <>
                <ItemList
                  dungeonData={dungeonData}
                  special={false}
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                  setViewing={setViewing}
                />
                {dungeonData.specialItemAvailable && (
                  <ItemList
                    dungeonData={dungeonData}
                    special={true}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                  />
                )}
                {selectedItem.type !== "" && (
                  <ItemDetails
                    dungeonData={dungeonData}
                    selectedItem={selectedItem}
                  />
                )}
              </>
            )}
            {viewing === "floors" && (
              <>
                <FloorList
                  dungeonData={dungeonData}
                  setViewing={setViewing}
                  floorIndex={floorIndex}
                  setFloorIndex={setFloorIndex}
                  dungeonType={dungeonType}
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

function ItemList({
  dungeonData,
  special,
  selectedItem,
  setSelectedItem,
  setViewing,
}) {
  function selectItem(index) {
    if (
      selectedItem.type === (special ? "specialItems" : "items") &&
      selectedItem.index === index
    ) {
      setSelectedItem({ type: "", index: 0 });
    } else {
      setSelectedItem({
        type: special ? "specialItems" : "items",
        index: index,
      });
    }
  }

  return (
    <>
      <div className="deepdungeonCategoryHeader">
        {dungeonData[special ? "specialItemName" : "itemName"]}{" "}
        {!special && (
          <span
            className="deepdungeonSwap"
            onClick={() => setViewing("floors")}
          >
            Floors
          </span>
        )}
      </div>
      <div className="deepdungeonList">
        {dungeonData[special ? "specialItems" : "items"].map((data, index) => (
          <div
            className="deepdungeonItemContainer"
            onClick={() => selectItem(index)}
            key={data.name}
          >
            {selectedItem.type === (special ? "specialItems" : "items") &&
              selectedItem.index === index && (
                <div className="deepdungeonItemSelected" />
              )}
            <img src={`https://xivapi.com${data.link}`} />
          </div>
        ))}
      </div>
    </>
  );
}

function ItemDetails({ dungeonData, selectedItem }) {
  return (
    <div className="deepdungeonItemDetail">
      <img
        src={`https://xivapi.com${dungeonData[selectedItem.type][
          selectedItem.index
        ].link.replace(".png", "")}_hr1.png`}
      />
      <div>
        <h1>{dungeonData[selectedItem.type][selectedItem.index].name}</h1>
        <p>{dungeonData[selectedItem.type][selectedItem.index].description}</p>
      </div>
    </div>
  );
}

function FloorList({
  dungeonData,
  setViewing,
  floorIndex,
  setFloorIndex,
  dungeonType,
}) {
  const navigate = useNavigate();
  const [toggleAll, setToggleAll] = useState();

  return (
    <>
      <div className="deepdungeonCategoryHeader">
        Floors
        <span className="deepdungeonSwap" onClick={() => setViewing("items")}>
          Items
        </span>
      </div>
      <select
        className="deepdungeonFloorSelect"
        value={floorIndex}
        onChange={(event) => {
          setFloorIndex(event.target.value);
          navigate(
            `/logs/deepdungeon/${dungeonType}/${event.target.value * 10 + 1}`
          );
          setToggleAll("close");
        }}
      >
        {dungeonData.floors.map((data, index) => (
          <option key={index} value={index}>
            {data.name}
          </option>
        ))}
      </select>
      <div className="deepdungeonCategoryHeader monstersHeader">
        Monsters{" "}
        <div className="deepdungeonAbilityToggleContainer">
          <div
            className="deepdungeonAbilityToggle"
            onClick={() => setToggleAll("open")}
          >
            Show
          </div>
          <div
            className="deepdungeonAbilityToggle"
            onClick={() => setToggleAll("close")}
          >
            Hide
          </div>
        </div>
      </div>
      <div className="deepdungeonFloorMonstersContainer">
        {dungeonData.floors[floorIndex].monsters.map((data, index) => (
          <Monster
            key={index}
            data={data}
            toggleAll={toggleAll}
            setToggleAll={setToggleAll}
          />
        ))}
      </div>
      <div className="deepdungeonCategoryHeader">Boss</div>
      <div className="deepdungeonFloorMonstersContainer">
        <Boss data={dungeonData.floors[floorIndex]} />
      </div>
    </>
  );
}

function Monster({ data, toggleAll, setToggleAll }) {
  const [showAbilities, setShowAbilities] = useState(false);

  useEffect(() => {
    if (toggleAll === "close") {
      setShowAbilities(false);
    } else if (toggleAll === "open") {
      setShowAbilities(true);
    }
    setToggleAll();
  }, [toggleAll]);

  return (
    <div className="deepdungeonMonster">
      <div
        className="deepdungeonMonsterName"
        onClick={() => setShowAbilities(!showAbilities)}
      >
        <img
          src={require(`../Assets/DeepDungeon/Threat/${data.threat}.png`)}
          alt={`Threat: ${data.threat[0].toUpperCase()}${data.threat.substring(
            1
          )}`}
          title={`Threat: ${data.threat[0].toUpperCase()}${data.threat.substring(
            1
          )}`}
          className="deepdungeonThreat"
        />{" "}
        <img
          src={require(`../Assets/DeepDungeon/Aggro/${data.aggro}.png`)}
          title={`Aggro: ${data.aggro[0].toUpperCase()}${data.aggro.substring(
            1
          )}`}
          className="deepdungeonAggro"
        />
        {data.name}{" "}
        {data.isPatrol && (
          <img
            src={require(`../Assets/DeepDungeon/patrol.png`)}
            alt="Patrol"
            title="Patrol"
            style={{ marginLeft: "5px" }}
          />
        )}
      </div>
      {showAbilities && (
        <div className="deepdungeonMonsterAbilities">
          {data.abilities.length !== 0 ? (
            data.abilities.map((ability, index) => (
              <div className="deepdungeonMonsterAbility" key={index}>
                <span className="deepdungeonMonsterAbilityName">
                  {ability.name}
                </span>
                {ability.description}
              </div>
            ))
          ) : (
            <div>- No abilities</div>
          )}
        </div>
      )}
    </div>
  );
}

function Boss({ data }) {
  const [showAbilities, setShowAbilities] = useState(false);

  return (
    <>
      <div
        className="deepdungeonBossName"
        onClick={() => setShowAbilities(!showAbilities)}
      >
        {data.hasBoss ? data.boss.name : "None"}
      </div>
      {showAbilities && data.hasBoss && (
        <div className="deepdungeonBossAbilities">
          {data.boss.abilities.length !== 0 ? (
            data.boss.abilities.map((ability, index) => (
              <div className="deepdungeonMonsterAbility" key={index}>
                <span className="deepdungeonMonsterAbilityName">
                  {ability.name}
                </span>
                {ability.description}
              </div>
            ))
          ) : (
            <div>- No abilities</div>
          )}
        </div>
      )}
    </>
  );
}

import { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router";
import "../main.css";
import "../CSS/item.css";
import config from "../config";

export default function Item() {
  let { state } = useLocation();
  const [searchResults, setSearchResults] = useState();
  const [itemData, setItemData] = useState();
  const [itemLinks, setItemLinks] = useState();
  const { paramitemname } = useParams();

  useEffect(() => {
    async function searchItem() {
      const response = await fetch(
        `https://xivapi.com/search?indexes=item&string=${paramitemname}`
      );
      if (response.ok) {
        let data = (await response.json()).Results;
        console.log(data);
        if (data.length === 0) {
          setItemData("no-item");
        } else if (data.length === 1) {
          getItemData(data[0].Url);
        } else if (paramitemname.toLowerCase() === data[0].Name.toLowerCase()) {
          getItemData(data[0].Url);
        } else {
          setSearchResults(data);
        }
      } else {
        setItemData("no-item");
      }
    }
    if (state.itemID) {
      getItemData(`/item/${state.itemID}`);
    } else {
      if (paramitemname) {
        if (!isNaN(paramitemname)) {
          getItemData(`/item/${paramitemname}`);
        } else {
          searchItem(paramitemname);
        }
      }
    }
  }, [state.itemID, paramitemname]);

  useEffect(() => {
    async function getLinks() {
      const response = await fetch(`${config.BACKEND_URL}/item/${itemData.ID}`);
      if (response.ok) {
        setItemLinks(await response.json());
      } else {
        alert(await response.json());
      }
    }
    if (itemData) {
      getLinks();
    }
  }, [itemData]);

  async function getItemData(url) {
    const response = await fetch(`https://xivapi.com${url}`);
    if (response.ok) {
      setItemData(await response.json());
    } else {
      setItemData("no-item");
    }
  }

  if (!paramitemname) {
    return;
  }

  return (
    <div>
      {searchResults && (
        <div>
          <h2>Search Results</h2>
          <div>{JSON.stringify(searchResults)}</div>
        </div>
      )}
      {itemData && (
        <div className="menuRegular">
          <div className="menuRegularBackground" />
          <div className="menuRegularContent">
            <div className="itemHeader">
              <img src={`https://xivapi.com${itemData.IconHD}`} />
              <div style={{ marginLeft: "10px" }}>
                <div className="itemCategory">
                  {itemData.ItemKind.Name}
                  {itemData.ItemSearchCategory &&
                    ` ➡ ${itemData.ItemSearchCategory.Name}`}
                  {itemData.ItemUICategory &&
                    ` ➡ ${itemData.ItemUICategory.Name}`}
                </div>
                <div
                  className={`itemName ${
                    itemData.Rarity === 2
                      ? "rarityGreen"
                      : itemData.Rarity === 3
                      ? "rarityBlue"
                      : itemData.Rarity === 4
                      ? "rarityPurple"
                      : itemData.Rarity === 7
                      ? "rarityPink"
                      : ""
                  }`}
                >
                  {itemData.Name ? itemData.Name : "None"}
                </div>
              </div>
            </div>
            <div className="itemDescription">
              {itemData.Description !== ""
                ? itemData.Description
                : "No description"}
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              Sells for: <div className="gilIcon" />
              {itemData.PriceLow.toLocaleString("en-US")}
            </div>
            {/* <div>{JSON.stringify(itemData)}</div> */}
            {itemLinks &&
              itemLinks.map((shop) => (
                <div style={{ margin: "10px" }}>
                  <h4>{shop.shopName}</h4>
                  {shop.npcData.map((npcDataEntry) => {
                    if (npcDataEntry[8]) {
                      return (
                        <>
                          <div>{npcDataEntry[1]}</div>
                          <div>
                            {npcDataEntry[9] ? npcDataEntry[9] : "N/A"} (X{" "}
                            {npcDataEntry[5].toFixed(1)}, Y{" "}
                            {npcDataEntry[6].toFixed(1)})
                          </div>
                        </>
                      );
                    }
                  })}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {shop.receive.map((receive) => (
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <img
                          title={receive.name}
                          style={{
                            width: "30px",
                            marginRight: "5px",
                          }}
                          src={`https://xivapi.com/i/${String(
                            parseInt(receive.icon / 1000) * 1000
                          ).padStart(6, "0")}/${String(receive.icon).padStart(
                            6,
                            "0"
                          )}.png`}
                        />{" "}
                        {receive.count !== 1 && receive.count}
                      </span>
                    ))}
                    <span style={{ marginLeft: "5px", marginRight: "5px" }}>
                      ←
                    </span>
                    {shop.cost.map((cost) => (
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <img
                          title={cost.name}
                          style={{
                            width: "30px",
                            marginRight: "5px",
                          }}
                          src={`https://xivapi.com/i/${String(
                            parseInt(cost.icon / 1000) * 1000
                          ).padStart(6, "0")}/${String(cost.icon).padStart(
                            6,
                            "0"
                          )}.png`}
                        />{" "}
                        {cost.count !== 1 && cost.count}
                      </span>
                    ))}
                  </div>
                </div>
              ))}
            {itemLinks && <div>{JSON.stringify(itemLinks)}</div>}
          </div>
        </div>
      )}
    </div>
  );
}

function buyData() {}

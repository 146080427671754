import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "../main.css";
import config from "../config";

export default function Login({ colorTheme, loggedIn, setLoggedIn }) {
  const navigate = useNavigate();
  const usernameRef = useRef();
  const passwordRef = useRef();

  useEffect(() => {
    document.title = "XIV Library | Login";
  }, []);

  useEffect(() => {
    if (loggedIn === "loggedIn") {
      navigate("/");
    }
  }, [loggedIn]);

  async function doLogin() {
    const response = await fetch(`${config.BACKEND_URL}/login`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: usernameRef.current.value,
        password: passwordRef.current.value,
      }),
    });
    if (response.ok) {
      console.log("Login successful");
      setLoggedIn("loggedIn");
      navigate("/");
    } else {
      alert(await response.json());
      return;
    }
  }

  return (
    <div className={`login ${colorTheme === "dark" && "themeDark2"}`}>
      <h1>Login Page</h1>
      <form className="loginContainer">
        <div className="loginDetails">
          <label>Username</label>
          <input className="searchInput" type="text" ref={usernameRef} />
        </div>
        <div className="loginDetails">
          <label>Password</label>
          <input
            className="searchInput"
            type="password"
            ref={passwordRef}
            onKeyUp={(event) => {
              if (event.key === "Enter") doLogin();
            }}
          />
        </div>
      </form>
      <div className="loginButtonContainer">
        <div
          className={`loginButton loginButtonGray ${
            colorTheme === "dark" && "themeDark"
          }`}
          onClick={() => {
            navigate("/register");
          }}
        >
          <div className="loginRegisterBackground" />
          <p className="loginButtonGray">Register</p>
        </div>
        <div
          className={`loginButton ${colorTheme === "dark" && "themeDark"}`}
          onClick={doLogin}
        >
          <div className="loginSubmitBackground" />
          <p className="loginButtonGold">Log In</p>
        </div>
      </div>
    </div>
  );
}

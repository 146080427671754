import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import config from "../../config";

const jobs = ["CRP", "BSM", "ARM", "GSM", "LTW", "WVR", "ALC", "CUL"];

export default function AdminCraftingLog({ loggedIn }) {
  const navigate = useNavigate();
  const jobRef = useRef();
  const [inputCategory, setInputCategory] = useState("level");
  const [inputSection, setInputSection] = useState(0);
  const [logs, setLogs] = useState();
  const [showCategory, setShowCategory] = useState({
    unsorted: { show: false },
    level: { show: false, sections: [] },
    master: { show: false, sections: [] },
    housing: { show: false, sections: [] },
    collectable: { show: false, sections: [] },
    customdelivery: { show: false, sections: [] },
    tribal: { show: false, sections: [] },
    class: { show: false, sections: [] },
    sidequest: { show: false, sections: [] },
    other: { show: false, sections: [] },
  });
  const [unsortedScroll, setUnsortedScroll] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    document.title = "XIV Library | [Admin] Crafting Log";
  }, []);

  useEffect(() => {
    if (loggedIn === "loggedIn") {
      async function adminCheck() {
        const response = await fetch(`${config.BACKEND_URL}/admin/verify`, {
          method: "POST",
          credentials: "include",
        });
        if (response.ok) {
          setIsAdmin(true);
        } else {
          navigate("/");
        }
      }
      adminCheck();
    } else if (loggedIn === "notLoggedIn") navigate("/login");
  }, [loggedIn]);

  const recipeTypes = [
    {
      name: "Level",
      id: "level",
    },
    {
      name: "Master",
      id: "master",
    },
    {
      name: "Housing",
      id: "housing",
    },
    {
      name: "Collectable",
      id: "collectable",
    },
    {
      name: "Custom Delivery",
      id: "customdelivery",
    },
    {
      name: "Tribal",
      id: "tribal",
    },
    {
      name: "Class",
      id: "class",
    },
    {
      name: "Sidequest",
      id: "sidequest",
    },
    {
      name: "Other",
      id: "other",
    },
  ];

  async function doSearch() {
    let fetchedData = "";
    const response = await fetch(
      `${config.BACKEND_URL}/admin/log/crafting/${jobs[jobRef.current.value]}`
    );
    if (response.ok) {
      fetchedData = await response.json();
    } else {
      alert("Something went wrong");
      return;
    }
    let tempList = [];
    let newRecipes = [];
    let recipeIDs = [];
    let page = 1;
    while (true) {
      const response = await fetch(
        `https://xivapi.com/search?indexes=recipe&page=${page}&limit=250&columns=ID,Icon,Name,RecipeLevelTable.ClassJobLevel,RecipeLevelTable.Stars,ItemResult.Rarity&filters=CraftTypeTargetID=${jobRef.current.value}`,
        { mode: "cors" }
      );
      let data = await response.json();
      tempList = tempList.concat(data.Results);
      if (data.Pagination.Results !== 250) {
        break;
      }
      page++;
    }
    let recipeChecklist = new Set(fetchedData.recipes);
    for (let x = 0; x < tempList.length; x++) {
      if (!recipeChecklist.has(tempList[x].ID)) {
        newRecipes.push(tempList[x]);
      }
      recipeIDs.push(tempList[x].ID);
    }

    fetchedData.recipes = recipeIDs;
    fetchedData.unsorted = fetchedData.unsorted.concat(newRecipes);
    setLogs(fetchedData);
  }

  async function doSave() {
    let newLogs = JSON.parse(JSON.stringify(logs));
    const response = await fetch(`${config.BACKEND_URL}/admin/log/crafting/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newLogs),
    });
    alert(await response.json());
  }

  async function doPublicSave() {
    let newLogs = JSON.parse(JSON.stringify(logs));
    delete newLogs.recipes;
    delete newLogs.unsorted;
    for (let x = 0; x < recipeTypes.length; x++) {
      for (let y = 0; y < newLogs[recipeTypes[x].id].length; y++) {
        for (let z = 0; z < newLogs[recipeTypes[x].id][y].entries.length; z++) {
          let newEntry = newLogs[recipeTypes[x].id][y].entries[z];
          newEntry.Rarity = newEntry.ItemResult.Rarity;
          newEntry.Level = newEntry.RecipeLevelTable.ClassJobLevel;
          newEntry.Stars = newEntry.RecipeLevelTable.Stars;
          delete newEntry.ItemResult;
          delete newEntry.RecipeLevelTable;
        }
      }
    }
    const response = await fetch(
      `${config.BACKEND_URL}/admin/log/crafting/public`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newLogs),
      }
    );
    alert(await response.json());
  }

  function UnsortedCategory() {
    const unsortedRef = useRef();

    useEffect(() => {
      if (unsortedRef.current) {
        unsortedRef.current.scrollTop = unsortedScroll;
      }
    }, []);

    function saveScroll() {
      setUnsortedScroll(unsortedRef.current.scrollTop);
    }

    if (!isAdmin) return "";
    else
      return (
        <div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShowCategory({
                ...showCategory,
                unsorted: {
                  show: !showCategory.unsorted.show,
                },
              });
            }}
          >
            <h1>Unsorted Recipes ({logs.unsorted.length})</h1>
          </div>
          {showCategory.unsorted.show && (
            <div>
              <>
                <h4>Send Recipes to: </h4>
                <div>
                  <label>Category</label>
                  <select
                    defaultValue={inputCategory}
                    onChange={(event) => {
                      setInputCategory(event.target.value);
                      setInputSection(0);
                    }}
                  >
                    {recipeTypes.map((data) => (
                      <option key={data.id} value={data.id}>
                        {data.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label>Section</label>
                  <select
                    defaultValue={inputSection}
                    onChange={(event) => {
                      setInputSection(event.target.value);
                    }}
                  >
                    {logs[inputCategory].map((data, index) => (
                      <option key={index} value={index}>
                        {data.section}
                      </option>
                    ))}
                  </select>
                </div>
              </>
              <div
                style={{ maxHeight: "800px", overflow: "auto" }}
                ref={unsortedRef}
              >
                {logs.unsorted.map((entry, index) => (
                  <UnsortedRecipe
                    key={entry.Name}
                    entry={entry}
                    index={index}
                    saveScroll={saveScroll}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      );
  }

  function UnsortedRecipe({ entry, index, saveScroll }) {
    const stars = "★".repeat(entry.RecipeLevelTable.Stars);

    return (
      <div
        style={{ display: "flex", alignItems: "center", margin: "5px auto" }}
      >
        <button
          style={{ marginRight: "10px" }}
          onClick={() => {
            if (logs[inputCategory][inputSection]) {
              saveScroll();
              let newLogs = JSON.parse(JSON.stringify(logs));
              let newEntry = newLogs.unsorted.splice(index, 1)[0];
              newLogs[inputCategory][inputSection].entries.push(newEntry);
              setLogs(newLogs);
            } else {
              alert("Section does not exist");
            }
          }}
        >
          Sort
        </button>
        <img src={`https://xivapi.com${entry.Icon}`} />
        <div style={{ display: "inline-block", marginLeft: "5px" }}>
          <div>{entry.Name}</div>
          <div>
            Lv.{entry.RecipeLevelTable.ClassJobLevel} {stars}
          </div>
        </div>
      </div>
    );
  }

  function RecipeCategory({ data }) {
    const sectionNameRef = useRef();
    function createSection() {
      let tempArr = JSON.parse(JSON.stringify(logs[data.id]));
      tempArr.unshift({ section: sectionNameRef.current.value, entries: [] });
      setLogs({ ...logs, [data.id]: tempArr });
      let tempArr2 = JSON.parse(JSON.stringify(showCategory));
      tempArr2[data.id].sections.unshift(false);
      setShowCategory(tempArr2);
    }

    return (
      <div>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            setShowCategory({
              ...showCategory,
              [data.id]: {
                ...showCategory[data.id],
                show: !showCategory[data.id].show,
              },
            });
          }}
        >
          <h1>
            {data.name} Recipes (
            {logs[data.id].length === 0
              ? 0
              : logs[data.id].reduce(
                  (accumulator, object) => accumulator + object.entries.length,
                  0
                )}
            )
          </h1>
        </div>
        {showCategory[data.id].show && (
          <div>
            <input type="text" ref={sectionNameRef} />
            <button onClick={createSection}>Add New Section</button>
            {logs[data.id].map((entry, index) => (
              <RecipeSection
                key={index}
                section={entry}
                recipeType={data.id}
                sectionIndex={index}
              />
            ))}
          </div>
        )}
      </div>
    );
  }

  function RecipeSection({ section, recipeType, sectionIndex }) {
    const [mouseover, setMouseover] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    return (
      <div>
        <div
          style={{
            display: "inline-flex",
            alignItems: "center",
            width: "100%",
          }}
          onMouseEnter={() => {
            setMouseover(true);
            setShowMenu(false);
          }}
          onMouseLeave={() => {
            setMouseover(false);
          }}
        >
          <div
            className="sectionArrowContainer"
            onClick={() => {
              let newShowCategory = JSON.parse(JSON.stringify(showCategory));
              newShowCategory[recipeType].sections[sectionIndex] =
                !newShowCategory[recipeType].sections[sectionIndex];
              setShowCategory(newShowCategory);
            }}
          >
            {showCategory[recipeType].sections[sectionIndex] ? (
              <div className="sectionOpened" />
            ) : (
              <div className="sectionClosed" />
            )}
          </div>
          <h3 style={{ display: "inline-block" }}>
            {section.section} ({section.entries.length})
          </h3>
          {mouseover && (
            <div
              style={{
                position: "relative",
                height: "30px",
              }}
            >
              <img
                src={require("../../Assets/dot_menu.png")}
                style={{ height: "100%", cursor: "pointer" }}
                onClick={() => {
                  setShowMenu(!showMenu);
                }}
              />
              {showMenu && (
                <div
                  style={{
                    position: "absolute",
                    border: "1px solid black",
                    backgroundColor: "white",
                    width: "140px",
                  }}
                >
                  <div
                    className="sectionMenuElement"
                    onClick={() => {
                      let newLogs = JSON.parse(JSON.stringify(logs));
                      newLogs.unsorted = newLogs.unsorted.concat(
                        section.entries
                      );
                      newLogs[recipeType].splice(sectionIndex, 1);
                      setLogs(newLogs);

                      let newShowCategory = JSON.parse(
                        JSON.stringify(showCategory)
                      );
                      newShowCategory[recipeType].sections.splice(
                        sectionIndex,
                        1
                      );
                      setShowCategory(newShowCategory);
                    }}
                  >
                    Delete Section
                  </div>
                  {sectionIndex !== 0 && (
                    <div
                      className="sectionMenuElement"
                      onClick={() => {
                        let newLogs = JSON.parse(JSON.stringify(logs));
                        let currentSection = newLogs[recipeType].splice(
                          sectionIndex,
                          1
                        )[0];
                        newLogs[recipeType].splice(
                          sectionIndex - 1,
                          0,
                          currentSection
                        );
                        setLogs(newLogs);
                        let newShowCategory = JSON.parse(
                          JSON.stringify(showCategory)
                        );
                        let currentShowStatus = newShowCategory[
                          recipeType
                        ].sections.splice(sectionIndex, 1)[0];
                        newShowCategory[recipeType].sections.splice(
                          sectionIndex - 1,
                          0,
                          currentShowStatus
                        );
                        setShowCategory(newShowCategory);
                      }}
                    >
                      Move Section Up
                    </div>
                  )}
                  {sectionIndex !== logs[recipeType].length - 1 && (
                    <div
                      className="sectionMenuElement"
                      onClick={() => {
                        let newLogs = JSON.parse(JSON.stringify(logs));
                        let currentSection = newLogs[recipeType].splice(
                          sectionIndex,
                          1
                        )[0];
                        newLogs[recipeType].splice(
                          sectionIndex + 1,
                          0,
                          currentSection
                        );
                        setLogs(newLogs);
                        let newShowCategory = JSON.parse(
                          JSON.stringify(showCategory)
                        );
                        let currentShowStatus = newShowCategory[
                          recipeType
                        ].sections.splice(sectionIndex, 1)[0];
                        newShowCategory[recipeType].sections.splice(
                          sectionIndex + 1,
                          0,
                          currentShowStatus
                        );
                        setShowCategory(newShowCategory);
                      }}
                    >
                      Move Section Down
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        {showCategory[recipeType].sections[sectionIndex] &&
          section.entries.map((entry, index) => (
            <SortedRecipe
              key={entry.Name}
              entry={entry}
              recipeType={recipeType}
              sectionIndex={sectionIndex}
              recipeIndex={index}
            />
          ))}
      </div>
    );
  }

  function SortedRecipe({ entry, recipeType, sectionIndex, recipeIndex }) {
    const [mouseover, setMouseover] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [mouseoverMove, setMouseoverMove] = useState(false);
    const stars = "★".repeat(entry.RecipeLevelTable.Stars);

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          margin: "5px auto",
        }}
        onMouseEnter={() => {
          setMouseover(true);
        }}
        onMouseLeave={() => {
          setMouseover(false);
          setShowMenu(false);
        }}
      >
        <div style={{ display: "inline-block" }}>
          {recipeIndex !== 0 && (
            <div>
              <div
                className="recipeArrowUp"
                onClick={() => {
                  let newLogs = JSON.parse(JSON.stringify(logs));
                  let newEntry = newLogs[recipeType][
                    sectionIndex
                  ].entries.splice(recipeIndex, 1)[0];
                  newLogs[recipeType][sectionIndex].entries.splice(
                    recipeIndex - 1,
                    0,
                    newEntry
                  );
                  setLogs(newLogs);
                }}
              />
            </div>
          )}
          {recipeIndex !==
            logs[recipeType][sectionIndex].entries.length - 1 && (
            <div>
              <div
                onClick={() => {
                  let newLogs = JSON.parse(JSON.stringify(logs));
                  let newEntry = newLogs[recipeType][
                    sectionIndex
                  ].entries.splice(recipeIndex, 1)[0];
                  newLogs[recipeType][sectionIndex].entries.splice(
                    recipeIndex + 1,
                    0,
                    newEntry
                  );
                  setLogs(newLogs);
                }}
                className="recipeArrowDown"
              />
            </div>
          )}
        </div>
        <img src={`https://xivapi.com${entry.Icon}`} />
        <div
          style={{
            display: "inline-block",
            marginLeft: "5px",
            textAlign: "left",
          }}
        >
          <div>{entry.Name}</div>
          <div>
            <div style={{ display: "inline-block" }}>
              Lv.{entry.RecipeLevelTable.ClassJobLevel}
            </div>
            <div style={{ display: "inline-block", marginLeft: "20px" }}>
              {stars}
            </div>
          </div>
        </div>

        {mouseover && (
          <div
            style={{
              display: "inline-block",
              position: "relative",
              height: "20px",
              marginLeft: "10px",
            }}
          >
            <img
              src={require("../../Assets/dot_menu.png")}
              style={{ height: "100%", cursor: "pointer" }}
              onClick={() => {
                setShowMenu(!showMenu);
              }}
            />
            {showMenu && (
              <div
                style={{
                  position: "absolute",
                  border: "1px solid black",
                  backgroundColor: "white",
                  width: "70px",
                }}
              >
                <div
                  className="sectionMenuElement"
                  onClick={() => {
                    let newLogs = JSON.parse(JSON.stringify(logs));
                    let newEntry = newLogs[recipeType][
                      sectionIndex
                    ].entries.splice(recipeIndex, 1)[0];
                    newLogs.unsorted.push(newEntry);
                    setLogs(newLogs);
                  }}
                >
                  Unsort
                </div>
                <div
                  className="sectionMenuElement"
                  style={{ position: "relative" }}
                  onMouseOver={() => {
                    setMouseoverMove(true);
                  }}
                  onMouseOut={() => {
                    setMouseoverMove(false);
                  }}
                >
                  Move to
                  {mouseoverMove && (
                    <div className="recipeMenuMove">
                      {logs[recipeType].map((section, index) => {
                        if (index !== sectionIndex) {
                          return (
                            <div
                              key={index}
                              className="recipeMenuMoveElement"
                              onClick={() => {
                                let newLogs = JSON.parse(JSON.stringify(logs));
                                let newEntry = newLogs[recipeType][
                                  sectionIndex
                                ].entries.splice(recipeIndex, 1)[0];
                                newLogs[recipeType][index].entries.push(
                                  newEntry
                                );
                                setLogs(newLogs);
                              }}
                            >
                              {section.section}
                            </div>
                          );
                        }
                      })}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  return (
    <div style={{ width: "60%", margin: "0 auto" }}>
      <h1>Admin: Crafting Log</h1>
      <select ref={jobRef}>
        {jobs.map((data, index) => (
          <option key={data} value={index}>
            {data}
          </option>
        ))}
      </select>
      <button onClick={doSearch}>Search</button>
      <button onClick={doSave}>Save</button>
      <button onClick={doPublicSave}>Save to Public DB</button>
      {logs && (
        <>
          <UnsortedCategory />
          {recipeTypes.map((data) => (
            <RecipeCategory key={data.id} data={data} />
          ))}
        </>
      )}
    </div>
  );
}

import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "../main.css";

export default function NavbarMobile({ colorTheme }) {
  const navbarRef = useRef();
  const [navbarOpen, setNavbarOpen] = useState(false);

  return (
    <div
      className="navbarMobile"
      style={{ fill: colorTheme === "light" ? "#69542d" : "#d8cfba" }}
      ref={navbarRef}
    >
      <svg
        height="32px"
        version="1.1"
        viewBox="0 0 32 32"
        width="32px"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => {
          setNavbarOpen(!navbarOpen);
        }}
      >
        <path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z" />
      </svg>
      {navbarOpen && (
        <NavbarMobileMenu
          navbarRef={navbarRef}
          setNavbarOpen={setNavbarOpen}
          colorTheme={colorTheme}
        />
      )}
    </div>
  );
}

function NavbarMobileMenu({ navbarRef, setNavbarOpen, colorTheme }) {
  useEffect(() => {
    function handleOutsideClick(event) {
      if (!navbarRef.current.contains(event.target)) {
        setNavbarOpen(false);
      }
    }
    document.addEventListener("mouseup", handleOutsideClick);
    return () => {
      document.removeEventListener("mouseup", handleOutsideClick);
    };
  }, [navbarRef, setNavbarOpen]);

  return (
    <>
      <div
        className={`navbarMobileMenu ${
          colorTheme === "light" ? "themeDark" : ""
        }`}
      >
        <div className="navbarMobileMenuCategory">Logs</div>
        <div className="navbarMobileMenuEntryContainer">
          <Link
            className="navbarMobileMenuEntry"
            to="/logs/crafting"
            onClick={() => setNavbarOpen(false)}
          >
            - Crafting Log
          </Link>
          <Link
            className="navbarMobileMenuEntry underConstructionMobile"
            to="#"
          >
            - Gathering Log
          </Link>
          <Link
            className="navbarMobileMenuEntry underConstructionMobile"
            to="#"
          >
            - Fishing Log
          </Link>
          <Link
            className="navbarMobileMenuEntry underConstructionMobile"
            to="#"
          >
            - Sightseeing Log
          </Link>
          <Link
            className="navbarMobileMenuEntry"
            to="/logs/deepdungeon"
            onClick={() => setNavbarOpen(false)}
          >
            - Deep Dungeon Log
          </Link>
        </div>
        <div className="navbarMobileMenuCategory">Collections</div>
        <div className="navbarMobileMenuEntryContainer">
          <Link
            className="navbarMobileMenuEntry underConstructionMobile"
            to="#"
          >
            - Achievements
          </Link>
          <Link
            className="navbarMobileMenuEntry underConstructionMobile"
            to="#"
          >
            - Mounts
          </Link>
          <Link
            className="navbarMobileMenuEntry underConstructionMobile"
            to="#"
          >
            - Minions
          </Link>
          <Link
            className="navbarMobileMenuEntry underConstructionMobile"
            to="#"
          >
            - Emotes
          </Link>
          <Link
            className="navbarMobileMenuEntry underConstructionMobile"
            to="#"
          >
            - Hairstyles
          </Link>
          <Link
            className="navbarMobileMenuEntry underConstructionMobile"
            to="#"
          >
            - Orchestrion Rolls
          </Link>
          <Link
            className="navbarMobileMenuEntry underConstructionMobile"
            to="#"
          >
            - Fashion Accessories
          </Link>
          <Link
            className="navbarMobileMenuEntry underConstructionMobile"
            to="#"
          >
            - Chocobo Bardings
          </Link>
          <Link
            className="navbarMobileMenuEntry underConstructionMobile"
            to="#"
          >
            - Triple Triad Cards
          </Link>
          <Link
            className="navbarMobileMenuEntry underConstructionMobile"
            to="#"
          >
            - Framer's Kits
          </Link>
          <Link
            className="navbarMobileMenuEntry underConstructionMobile"
            to="#"
          >
            - Relic Weapons / Tools
          </Link>
          <Link
            className="navbarMobileMenuEntry underConstructionMobile"
            to="#"
          >
            - Blue Mage Spells
          </Link>
        </div>
        <div className="navbarMobileMenuCategory">Sets</div>
        <div className="navbarMobileMenuEntryContainer">
          <Link
            className="navbarMobileMenuEntry underConstructionMobile"
            to="#"
          >
            - Gear Sets
          </Link>
        </div>
      </div>
      {/* <div className="navbarDivider" style={{ marginBottom: 0 }} /> */}
    </>
  );
}

import { useEffect } from "react";
import config from "../config";

export default function Register({ colorTheme }) {
  useEffect(() => {
    document.title = "XIV Library | Register";
  }, []);

  async function doRegister(event) {
    event.preventDefault();
    const response = await fetch(`${config.BACKEND_URL}/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: event.target.form[0].value,
        password: event.target.form[1].value,
        email: event.target.form[2].value,
      }),
    });
    alert(await response.json());
  }

  return (
    <div>
      <h1>Registration Page</h1>
      <form>
        <div>
          <label>Username</label>
          <input type="text" />
        </div>
        <div>
          <label>Password</label>
          <input type="password" />
        </div>
        <div>
          <label>Email Address</label>
          <input type="email" />
        </div>
        <div>
          <input
            type="submit"
            value="Register"
            onClick={(event) => doRegister(event)}
          />
        </div>
      </form>
    </div>
  );
}

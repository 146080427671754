import "../main.css";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

export default function Navbar({ colorTheme }) {
  const logsRef = useRef();
  const collectionsRef = useRef();
  const setsRef = useRef();
  const [navbarCategoryOpen, setNavbarCategoryOpen] = useState();

  return (
    <>
      <div
        className={`navbar ${
          colorTheme === "light" ? "themeLight" : "themeDark"
        }`}
      >
        <div
          className="navbarCategory"
          ref={logsRef}
          onClick={() => {
            if (navbarCategoryOpen === 0) {
              setNavbarCategoryOpen();
            } else {
              setNavbarCategoryOpen(0);
            }
          }}
        >
          <p>Logs</p>
          {navbarCategoryOpen === 0 && (
            <>
              <div className="navbarCategoryBorder" />
              <CategoryLogs
                logsRef={logsRef}
                setNavbarCategoryOpen={setNavbarCategoryOpen}
              />
            </>
          )}
        </div>
        <div
          className="navbarCategory"
          ref={collectionsRef}
          onClick={() => {
            if (navbarCategoryOpen === 1) {
              setNavbarCategoryOpen();
            } else {
              setNavbarCategoryOpen(1);
            }
          }}
        >
          <p>Collections</p>
          {navbarCategoryOpen === 1 && (
            <>
              <div className="navbarCategoryBorder" />
              <CategoryCollections
                collectionsRef={collectionsRef}
                setNavbarCategoryOpen={setNavbarCategoryOpen}
              />
            </>
          )}
        </div>
        <div
          className="navbarCategory"
          ref={setsRef}
          onClick={() => {
            if (navbarCategoryOpen === 2) {
              setNavbarCategoryOpen();
            } else {
              setNavbarCategoryOpen(2);
            }
          }}
        >
          <p>Sets</p>
          {navbarCategoryOpen === 2 && (
            <>
              <div className="navbarCategoryBorder" />
              <CategorySets
                setsRef={setsRef}
                setNavbarCategoryOpen={setNavbarCategoryOpen}
              />
            </>
          )}
        </div>
      </div>
      <div className="navbarDivider" />
    </>
  );
}

function useOutsideClick(categoryRef, setNavbarCategoryOpen) {
  useEffect(() => {
    function handleOutsideClick(event) {
      if (!categoryRef.current.contains(event.target)) {
        setNavbarCategoryOpen();
      }
    }
    document.addEventListener("mouseup", handleOutsideClick);
    return () => {
      document.removeEventListener("mouseup", handleOutsideClick);
    };
  }, [categoryRef, setNavbarCategoryOpen]);
}

function CategoryLogs({ logsRef, setNavbarCategoryOpen }) {
  useOutsideClick(logsRef, setNavbarCategoryOpen);

  return (
    <div className="navbarCategoryMenu">
      <div className="navbarCategoryMenuBackground" />
      <Link to="/logs/crafting" className="navbarCategoryMenuEntry">
        <label className="navbarCategoryMenuEntryHover" />
        <img src={require("../Assets/Navbar/crafting.png")} />
        <p>Crafting Log</p>
      </Link>
      <Link to="#" className="navbarCategoryMenuEntry">
        <label className="navbarCategoryMenuEntryHover" />
        <img src={require("../Assets/Navbar/gathering.png")} />
        <p className="underConstruction">
          <s>Gathering Log</s>
        </p>
      </Link>
      <Link to="#" className="navbarCategoryMenuEntry">
        <label className="navbarCategoryMenuEntryHover" />
        <img src={require("../Assets/Navbar/fishing.png")} />
        <p className="underConstruction">
          <s>Fishing Log</s>
        </p>
      </Link>
      <Link to="#" className="navbarCategoryMenuEntry">
        <label className="navbarCategoryMenuEntryHover" />
        <img src={require("../Assets/Navbar/sightseeing.png")} />
        <p className="underConstruction">
          <s>Sightseeing Log</s>
        </p>
      </Link>
      <Link to="/logs/deepdungeon" className="navbarCategoryMenuEntry">
        <label className="navbarCategoryMenuEntryHover" />
        <img src={require("../Assets/Navbar/deep_dungeon.png")} />
        <p>Deep Dungeon Log</p>
      </Link>
    </div>
  );
}

function CategoryCollections({ collectionsRef, setNavbarCategoryOpen }) {
  useOutsideClick(collectionsRef, setNavbarCategoryOpen);

  return (
    <div className="navbarCategoryMenu">
      <div className="navbarCategoryMenuBackground" />
      <Link to="#" className="navbarCategoryMenuEntry">
        <label className="navbarCategoryMenuEntryHover" />
        <img src={require("../Assets/Navbar/achievement.png")} />
        <p className="underConstruction">
          <s>Achievements</s>
        </p>
      </Link>
      <Link to="#" className="navbarCategoryMenuEntry">
        <label className="navbarCategoryMenuEntryHover" />
        <img src={require("../Assets/Navbar/mount.png")} />
        <p className="underConstruction">
          <s>Mounts</s>
        </p>
      </Link>
      <Link to="#" className="navbarCategoryMenuEntry">
        <label className="navbarCategoryMenuEntryHover" />
        <img src={require("../Assets/Navbar/minion.png")} />
        <p className="underConstruction">
          <s>Minions</s>
        </p>
      </Link>
      <Link to="#" className="navbarCategoryMenuEntry">
        <label className="navbarCategoryMenuEntryHover" />
        <img src={require("../Assets/Navbar/emote.png")} />
        <p className="underConstruction">
          <s>Emotes</s>
        </p>
      </Link>
      <Link to="#" className="navbarCategoryMenuEntry">
        <label className="navbarCategoryMenuEntryHover" />
        <img src={require("../Assets/Navbar/hairstyle.png")} />
        <p className="underConstruction">
          <s>Hairstyles</s>
        </p>
      </Link>
      <Link to="#" className="navbarCategoryMenuEntry">
        <label className="navbarCategoryMenuEntryHover" />
        <img src={require("../Assets/Navbar/orchestrion.png")} />
        <p className="underConstruction">
          <s>Orchestrion Rolls</s>
        </p>
      </Link>
      <Link to="#" className="navbarCategoryMenuEntry">
        <label className="navbarCategoryMenuEntryHover" />
        <img src={require("../Assets/Navbar/fashion.png")} />
        <p className="underConstruction">
          <s>Fashion Accessories</s>
        </p>
      </Link>
      <Link to="#" className="navbarCategoryMenuEntry">
        <label className="navbarCategoryMenuEntryHover" />
        <img src={require("../Assets/Navbar/barding.png")} />
        <p className="underConstruction">
          <s>Chocobo Bardings</s>
        </p>
      </Link>
      <Link to="#" className="navbarCategoryMenuEntry">
        <label className="navbarCategoryMenuEntryHover" />
        <img src={require("../Assets/Navbar/card.png")} />
        <p className="underConstruction">
          <s>Triple Triad Cards</s>
        </p>
      </Link>
      <Link to="#" className="navbarCategoryMenuEntry">
        <label className="navbarCategoryMenuEntryHover" />
        <img src={require("../Assets/Navbar/framer.png")} />
        <p className="underConstruction">
          <s>Framer's Kits</s>
        </p>
      </Link>
      <Link to="#" className="navbarCategoryMenuEntry">
        <label className="navbarCategoryMenuEntryHover" />
        <img src={require("../Assets/Navbar/relic.png")} />
        <p className="underConstruction">
          <s>Relic Weapons / Tools</s>
        </p>
      </Link>
      <Link to="#" className="navbarCategoryMenuEntry">
        <label className="navbarCategoryMenuEntryHover" />
        <img src={require("../Assets/Navbar/blue_mage.png")} />
        <p className="underConstruction">
          <s>Blue Mage Spells</s>
        </p>
      </Link>
    </div>
  );
}

function CategorySets({ setsRef, setNavbarCategoryOpen }) {
  useOutsideClick(setsRef, setNavbarCategoryOpen);

  return (
    <div className="navbarCategoryMenu">
      <div className="navbarCategoryMenuBackground" />
      <Link to="#" className="navbarCategoryMenuEntry">
        <label className="navbarCategoryMenuEntryHover" />
        <img src={require("../Assets/Navbar/gear_set.png")} />
        <p className="underConstruction">
          <s>Gear Sets</s>
        </p>
      </Link>
    </div>
  );
}

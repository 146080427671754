import "../main.css";
import config from "../config";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import NavbarMobile from "./NavbarMobile";

export default function Header({
  colorTheme,
  setColorTheme,
  loggedIn,
  setLoggedIn,
}) {
  const configRef = useRef();
  const profileRef = useRef();
  const navigate = useNavigate();
  const [configVisible, setConfigVisible] = useState(false);
  const [profileVisible, setProfileVisible] = useState(false);

  async function doLogout() {
    const response = await fetch(`${config.BACKEND_URL}/logout`, {
      method: "POST",
      credentials: "include",
    });
    if (response.ok) {
      setLoggedIn("notLoggedIn");
      navigate("/");
    }
  }

  return (
    <header>
      <NavbarMobile colorTheme={colorTheme} />
      <div className="xivlibraryTitle">
        <Link to="/">
          <img
            className="xivlibraryLogo"
            src={require("../Assets/xivlibrary_logo.png")}
          />
        </Link>
        <Link to="/">
          <img
            className="xivlibraryText"
            src={
              colorTheme === "light"
                ? require("../Assets/xivlibrary_text.png")
                : require("../Assets/xivlibrary_text_white.png")
            }
          />
        </Link>
      </div>
      <div className="xivlibraryConfig">
        <div className="configButtonContainer">
          <a
            href="https://discord.gg/vRtAczMHcF"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="configButton"
              src={require("../Assets/discord.png")}
            />
          </a>
        </div>
        <div
          className="configButtonContainer"
          onClick={() => setConfigVisible(!configVisible)}
          ref={configRef}
        >
          <img className="configButton" src={require("../Assets/config.png")} />
          {configVisible && (
            <ConfigMenu
              configRef={configRef}
              setConfigVisible={setConfigVisible}
              colorTheme={colorTheme}
              setColorTheme={setColorTheme}
            />
          )}
        </div>
        <div
          className="configButtonContainer"
          onClick={() => setProfileVisible(!profileVisible)}
          ref={profileRef}
        >
          <img
            className="configButton"
            src={require(`../Assets/${
              loggedIn === "loggedIn" ? "profile_active" : "profile"
            }.png`)}
          />
          {profileVisible && (
            <ProfileMenu
              profileRef={profileRef}
              setProfileVisible={setProfileVisible}
              loggedIn={loggedIn}
              doLogout={doLogout}
            />
          )}
        </div>
      </div>
    </header>
  );
}
function ConfigMenu({
  configRef,
  setConfigVisible,
  colorTheme,
  setColorTheme,
}) {
  useEffect(() => {
    function handleOutsideClick(event) {
      if (!configRef.current.contains(event.target)) {
        setConfigVisible(false);
      }
    }
    document.addEventListener("mouseup", handleOutsideClick);
    return () => {
      document.removeEventListener("mouseup", handleOutsideClick);
    };
  }, [configRef, setConfigVisible]);

  return (
    <div className="configMenu">
      <div className="configMenuBackground" />
      <div className="configMenuContent">
        {colorTheme === "light" ? (
          <div
            className="configMenuEntry"
            onClick={() => setColorTheme("dark")}
          >
            <label className="configMenuHover" />
            <p>Switch to dark mode</p>
          </div>
        ) : (
          <div
            className="configMenuEntry"
            onClick={() => setColorTheme("light")}
          >
            <label className="configMenuHover" />
            <p>Switch to light mode</p>
          </div>
        )}
        {/* <div
          className="configMenuEntry"
          onClick={() => {
            console.log(2);
          }}
        >
          <label className="configMenuHover" />
          <p>Test2</p>
        </div> */}
      </div>
    </div>
  );
}

function ProfileMenu({ profileRef, setProfileVisible, loggedIn, doLogout }) {
  useEffect(() => {
    function handleOutsideClick(event) {
      if (!profileRef.current.contains(event.target)) {
        setProfileVisible(false);
      }
    }
    document.addEventListener("mouseup", handleOutsideClick);
    return () => {
      document.removeEventListener("mouseup", handleOutsideClick);
    };
  }, [profileRef, setProfileVisible]);

  return (
    <div className="configMenu">
      <div className="configMenuBackground" />
      <div className="configMenuContent">
        {loggedIn === "loggedIn" ? (
          <>
            <div className="configMenuEntry" onClick={doLogout}>
              <label className="configMenuHover" />
              <p>Log Out</p>
            </div>
          </>
        ) : (
          <Link className="configMenuEntry" to="/login">
            <label className="configMenuHover" />
            <p>Log In</p>
          </Link>
        )}
      </div>
    </div>
  );
}

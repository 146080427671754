import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import config from "../../config";
import "../../CSS/admin.css";

export default function AdminDeepDungeon({ loggedIn }) {
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);
  const [logs, setLogs] = useState([]);
  const [selectedLog, setSelectedLog] = useState("default");

  useEffect(() => {
    document.title = "XIV Library | [Admin] Deep Dungeon Log";
  }, []);

  useEffect(() => {
    if (loggedIn === "loggedIn") {
      async function adminCheck() {
        const response = await fetch(`${config.BACKEND_URL}/admin/verify`, {
          method: "POST",
          credentials: "include",
        });
        if (response.ok) {
          setIsAdmin(true);
        } else {
          navigate("/");
        }
      }
      adminCheck();
    } else if (loggedIn === "notLoggedIn") navigate("/login");
  }, [loggedIn]);

  async function getLogs() {
    const response = await fetch(
      `${config.BACKEND_URL}/admin/log/deepdungeon`,
      {
        method: "GET",
        credentials: "include",
      }
    );
    if (response.ok) {
      setLogs(await response.json());
    } else {
      alert(await response.json());
    }
  }

  async function updateLog() {
    const response = await fetch(
      `${config.BACKEND_URL}/admin/log/deepdungeon`,
      {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(logs[selectedLog]),
      }
    );
    alert(await response.json());
  }

  if (!isAdmin) return "";
  else
    return (
      <div style={{ width: "60%", margin: "0 auto" }}>
        <h1 style={{ textAlign: "center" }}>Admin: Deep Dungeon</h1>
        <div style={{ width: "fit-content", margin: "0 auto" }}>
          <button onClick={getLogs}>Get all logs</button>
          <select
            style={{ marginRight: "5px", marginLeft: "5px" }}
            onChange={(event) => setSelectedLog(event.target.value)}
            defaultValue="default"
          >
            <option value="default" disabled={true}>
              Select Deep Dungeon
            </option>
            {logs.map((data, index) => (
              <option key={index} value={index}>
                {data.name}
              </option>
            ))}
          </select>
          <button onClick={updateLog}>Save selected log</button>
        </div>
        {selectedLog !== "default" && (
          <div style={{ margin: "20px" }}>
            <DDSection
              logs={logs}
              setLogs={setLogs}
              selectedLog={selectedLog}
              sectionName="Name"
              sectionId="name"
            />
            <DDSection
              logs={logs}
              setLogs={setLogs}
              selectedLog={selectedLog}
              sectionName="Name (Short)"
              sectionId="nameShort"
            />
            <DDSection
              logs={logs}
              setLogs={setLogs}
              selectedLog={selectedLog}
              sectionName="Item Name"
              sectionId="itemName"
            />
            <DDSection
              logs={logs}
              setLogs={setLogs}
              selectedLog={selectedLog}
              sectionName="Special Item Name"
              sectionId="specialItemName"
            />
            <div>
              Special Items:{" "}
              <input
                type="checkbox"
                checked={logs[selectedLog].specialItemAvailable}
                onChange={(event) => {
                  let newLogs = JSON.parse(JSON.stringify(logs));
                  newLogs[selectedLog].specialItemAvailable =
                    event.target.checked;
                  setLogs(newLogs);
                }}
              />
            </div>
            <ItemList
              logs={logs}
              setLogs={setLogs}
              selectedLog={selectedLog}
              special={false}
            />
            <ItemList
              logs={logs}
              setLogs={setLogs}
              selectedLog={selectedLog}
              special={true}
            />
            <FloorList
              logs={logs}
              setLogs={setLogs}
              selectedLog={selectedLog}
            />
          </div>
        )}
      </div>
    );
}

function DDSection({ logs, setLogs, selectedLog, sectionName, sectionId }) {
  const [edit, setEdit] = useState(false);
  const inputRef = useRef();

  return (
    <div className="mouseover">
      {sectionName}:{" "}
      {edit === true ? (
        <>
          <input defaultValue={logs[selectedLog][sectionId]} ref={inputRef} />{" "}
          <button
            onClick={() => {
              let newLogs = JSON.parse(JSON.stringify(logs));
              newLogs[selectedLog][sectionId] = inputRef.current.value;
              setLogs(newLogs);
              setEdit(false);
            }}
          >
            Save
          </button>
        </>
      ) : (
        <>
          <span>{logs[selectedLog][sectionId]}</span>{" "}
          <button onClick={() => setEdit(true)} className="hidden">
            Edit
          </button>
        </>
      )}
    </div>
  );
}

function ItemList({ logs, setLogs, selectedLog, special }) {
  function addItem() {
    let newLogs = JSON.parse(JSON.stringify(logs));
    newLogs[selectedLog][special === true ? "specialItems" : "items"].push({
      name: "Item",
      link: "",
      description: "Description",
    });
    setLogs(newLogs);
  }

  return (
    <div>
      <h3>{special === true ? "Special Item" : "Item"} List</h3>
      <button onClick={addItem}>Add new item</button>
      {logs[selectedLog][special === true ? "specialItems" : "items"].map(
        (data, index) => (
          <ItemEntry
            key={index}
            logs={logs}
            setLogs={setLogs}
            selectedLog={selectedLog}
            index={index}
            special={special}
          />
        )
      )}
    </div>
  );
}

function ItemEntry({ logs, setLogs, selectedLog, index, special }) {
  const [edit, setEdit] = useState(false);
  const nameRef = useRef();
  const linkRef = useRef();
  const descriptionRef = useRef();

  function editItem(index) {
    let newLogs = JSON.parse(JSON.stringify(logs));
    newLogs[selectedLog][special === true ? "specialItems" : "items"][index] = {
      name: nameRef.current.value,
      link: linkRef.current.value,
      description: descriptionRef.current.value,
    };
    setLogs(newLogs);
    setEdit(false);
  }

  function deleteItem(index) {
    let newLogs = JSON.parse(JSON.stringify(logs));
    newLogs[selectedLog][special === true ? "specialItems" : "items"].splice(
      index,
      1
    );
    setLogs(newLogs);
    setEdit(false);
  }

  function moveItemUp(index) {
    let newLogs = JSON.parse(JSON.stringify(logs));
    let newEntry = newLogs[selectedLog][
      special === true ? "specialItems" : "items"
    ].splice(index, 1)[0];
    newLogs[selectedLog][special === true ? "specialItems" : "items"].splice(
      index - 1,
      0,
      newEntry
    );
    setLogs(newLogs);
    setEdit(false);
  }

  function moveItemDown(index) {
    let newLogs = JSON.parse(JSON.stringify(logs));
    let newEntry = newLogs[selectedLog][
      special === true ? "specialItems" : "items"
    ].splice(index, 1)[0];
    newLogs[selectedLog][special === true ? "specialItems" : "items"].splice(
      index + 1,
      0,
      newEntry
    );
    setLogs(newLogs);
    setEdit(false);
  }

  return (
    <>
      {edit ? (
        <div>
          <div>
            <label>Name</label>
            <input
              type="text"
              defaultValue={
                logs[selectedLog][special === true ? "specialItems" : "items"][
                  index
                ].name
              }
              ref={nameRef}
            />
          </div>
          <div>
            <label>Link</label>
            <input
              type="text"
              defaultValue={
                logs[selectedLog][special === true ? "specialItems" : "items"][
                  index
                ].link
              }
              ref={linkRef}
            />
          </div>
          <div>
            <label>Description</label>
            <input
              type="text"
              defaultValue={
                logs[selectedLog][special === true ? "specialItems" : "items"][
                  index
                ].description
              }
              ref={descriptionRef}
            />
          </div>
          <button onClick={() => editItem(index)}>Done</button>
          {index !== 0 && (
            <button onClick={() => moveItemUp(index)}>Move Up</button>
          )}
          {index !==
            logs[selectedLog][special === true ? "specialItems" : "items"]
              .length -
              1 && (
            <button onClick={() => moveItemDown(index)}>Move Down</button>
          )}
          <button onClick={() => deleteItem(index)}>Delete</button>
        </div>
      ) : (
        <div className="mouseover">
          <img
            style={{ width: "30px", height: "30px" }}
            src={`https://xivapi.com${
              logs[selectedLog][special === true ? "specialItems" : "items"][
                index
              ].link
            }`}
            onError={(event) =>
              (event.target.src = require("../../Assets/placeholder.png"))
            }
          />
          {
            logs[selectedLog][special === true ? "specialItems" : "items"][
              index
            ].name
          }
          <button onClick={() => setEdit(true)} className="hidden">
            Edit
          </button>
        </div>
      )}
    </>
  );
}

function FloorList({ logs, setLogs, selectedLog }) {
  function addFloor() {
    let newLogs = JSON.parse(JSON.stringify(logs));
    newLogs[selectedLog].floors.push({
      name: "1 - 10",
      monsters: [],
      hasBoss: true,
      boss: { name: "boss", abilities: [] },
    });
    setLogs(newLogs);
  }

  return (
    <div>
      <h3>Floor List</h3>
      <button onClick={addFloor}>Add Floor</button>
      {logs[selectedLog].floors.map((data, index) => (
        <FloorEntry
          key={index}
          logs={logs}
          setLogs={setLogs}
          selectedLog={selectedLog}
          index={index}
        />
      ))}
    </div>
  );
}

function FloorEntry({ logs, setLogs, selectedLog, index }) {
  const [edit, setEdit] = useState(false);

  function editName(event) {
    let newLogs = JSON.parse(JSON.stringify(logs));
    newLogs[selectedLog].floors[index].name = event.target.value;
    setLogs(newLogs);
  }

  function deleteFloor() {
    let newLogs = JSON.parse(JSON.stringify(logs));
    newLogs[selectedLog].floors.splice(index, 1);
    setLogs(newLogs);
    setEdit(false);
  }

  function toggleBoss(event) {
    let newLogs = JSON.parse(JSON.stringify(logs));
    newLogs[selectedLog].floors[index].hasBoss = event.target.checked;
    setLogs(newLogs);
  }

  function addMonster() {
    let newLogs = JSON.parse(JSON.stringify(logs));
    newLogs[selectedLog].floors[index].monsters.push({
      name: "monster",
      threat: "low",
      isPatrol: false,
      abilities: [],
      aggro: "sight",
    });
    setLogs(newLogs);
  }

  return (
    <div
      className={!edit ? "mouseover" : undefined}
      style={{ marginTop: "10px", marginBottom: "10px" }}
    >
      <div>
        <span style={{ fontSize: "20px" }}>
          Floors {logs[selectedLog].floors[index].name}{" "}
        </span>
        {edit ? (
          <>
            <button onClick={() => setEdit(false)}>Ok</button>
            <button onClick={deleteFloor}>Delete</button>
          </>
        ) : (
          <button onClick={() => setEdit(true)} className="hidden">
            Edit
          </button>
        )}
      </div>
      {edit && (
        <>
          <div style={{ marginLeft: "20px" }}>
            <div>
              <label> Floor Name</label>
              <input
                style={{ width: "70px" }}
                value={logs[selectedLog].floors[index].name}
                onChange={(event) => editName(event)}
              />
            </div>
            <div>
              <label>Has Boss</label>
              <input
                type="checkbox"
                checked={logs[selectedLog].floors[index].hasBoss}
                onChange={(event) => toggleBoss(event)}
              />
            </div>
            {logs[selectedLog].floors[index].hasBoss && (
              <BossEntry
                logs={logs}
                selectedLog={selectedLog}
                setLogs={setLogs}
                index={index}
              />
            )}
            <div>
              <h3 style={{ display: "inline-block", margin: 0 }}>Monsters</h3>{" "}
              <button onClick={addMonster}>Add</button>
            </div>
            <div style={{ marginLeft: "10px" }}>
              {logs[selectedLog].floors[index].monsters.map(
                (data, monsterIndex) => (
                  <MonsterEntry
                    key={monsterIndex}
                    logs={logs}
                    selectedLog={selectedLog}
                    index={index}
                    setLogs={setLogs}
                    monsterIndex={monsterIndex}
                  />
                )
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

function BossEntry({ logs, selectedLog, setLogs, index }) {
  const [editBoss, setEditBoss] = useState(false);
  const [bossAbilities, setBossAbilities] = useState(
    logs[selectedLog].floors[index].boss.abilities
  );
  const bossNameRef = useRef();

  function saveBoss() {
    let newLogs = JSON.parse(JSON.stringify(logs));
    newLogs[selectedLog].floors[index].boss.name = bossNameRef.current.value;
    newLogs[selectedLog].floors[index].boss.abilities = bossAbilities;
    setLogs(newLogs);
    setEditBoss(false);
  }

  function setBossAbilityName(event, index) {
    let newBossAbilities = JSON.parse(JSON.stringify(bossAbilities));
    newBossAbilities[index].name = event.target.value;
    setBossAbilities(newBossAbilities);
  }

  function setBossAbilityDescription(event, index) {
    let newBossAbilities = JSON.parse(JSON.stringify(bossAbilities));
    newBossAbilities[index].description = event.target.value;
    setBossAbilities(newBossAbilities);
  }

  function deleteBossAbility(index) {
    let newBossAbilities = JSON.parse(JSON.stringify(bossAbilities));
    newBossAbilities.splice(index, 1);
    setBossAbilities(newBossAbilities);
  }
  return (
    <div>
      <div>
        <h3
          style={{
            display: "inline-block",
            margin: "0 5px 0 0 ",
          }}
        >
          Boss
        </h3>
        {editBoss ? (
          <button onClick={saveBoss}>Ok</button>
        ) : (
          <button
            onClick={() => {
              setEditBoss(true);
              setBossAbilities(logs[selectedLog].floors[index].boss.abilities);
            }}
          >
            Edit
          </button>
        )}
      </div>
      {editBoss ? (
        <div style={{ marginLeft: "10px" }}>
          <div>
            <label>Name: </label>
            <input
              ref={bossNameRef}
              type="text"
              defaultValue={logs[selectedLog].floors[index].boss.name}
            />
          </div>
          <div>
            <div>
              Abilities{" "}
              <button
                onClick={() =>
                  setBossAbilities([
                    ...bossAbilities,
                    { name: "", description: "" },
                  ])
                }
              >
                Add
              </button>
            </div>
            <div style={{ marginLeft: "10px" }}>
              {bossAbilities.map((data, index) => (
                <div>
                  Name{" "}
                  <input
                    type="text"
                    value={data.name}
                    onChange={(event) => setBossAbilityName(event, index)}
                  />
                  Description{" "}
                  <input
                    type="text"
                    value={data.description}
                    onChange={(event) =>
                      setBossAbilityDescription(event, index)
                    }
                  />
                  <button
                    style={{ marginLeft: "10px" }}
                    onClick={() => deleteBossAbility(index)}
                  >
                    Delete
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div style={{ marginLeft: "10px" }}>
          <div>Name: {logs[selectedLog].floors[index].boss.name}</div>
          <div>Abilities</div>
          <div style={{ marginLeft: "10px" }}>
            {logs[selectedLog].floors[index].boss.abilities.map((data) => (
              <div>
                {data.name}: {data.description}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

function MonsterEntry({ logs, selectedLog, setLogs, index, monsterIndex }) {
  const [editMonster, setEditMonster] = useState(false);

  function saveMonster() {
    setEditMonster(false);
  }

  function editName(event) {
    let newLogs = JSON.parse(JSON.stringify(logs));
    newLogs[selectedLog].floors[index].monsters[monsterIndex].name =
      event.target.value;
    setLogs(newLogs);
  }

  function editThreat(event) {
    let newLogs = JSON.parse(JSON.stringify(logs));
    newLogs[selectedLog].floors[index].monsters[monsterIndex].threat =
      event.target.value;
    setLogs(newLogs);
  }

  function addAbility() {
    let newLogs = JSON.parse(JSON.stringify(logs));
    newLogs[selectedLog].floors[index].monsters[monsterIndex].abilities.push({
      name: "",
      description: "",
    });
    setLogs(newLogs);
  }

  function setAbilityName(event, abilityIndex) {
    let newLogs = JSON.parse(JSON.stringify(logs));
    newLogs[selectedLog].floors[index].monsters[monsterIndex].abilities[
      abilityIndex
    ].name = event.target.value;
    setLogs(newLogs);
  }

  function setAbilityDescription(event, abilityIndex) {
    let newLogs = JSON.parse(JSON.stringify(logs));
    newLogs[selectedLog].floors[index].monsters[monsterIndex].abilities[
      abilityIndex
    ].description = event.target.value;
    setLogs(newLogs);
  }

  function deleteAbility(abilityIndex) {
    let newLogs = JSON.parse(JSON.stringify(logs));
    newLogs[selectedLog].floors[index].monsters[monsterIndex].abilities.splice(
      abilityIndex,
      1
    );
    setLogs(newLogs);
  }

  function editPatrol(event) {
    let newLogs = JSON.parse(JSON.stringify(logs));
    newLogs[selectedLog].floors[index].monsters[monsterIndex].isPatrol =
      event.target.checked;
    setLogs(newLogs);
  }

  function editAggro(event) {
    let newLogs = JSON.parse(JSON.stringify(logs));
    newLogs[selectedLog].floors[index].monsters[monsterIndex].aggro =
      event.target.value;
    setLogs(newLogs);
  }

  function deleteMonster() {
    let newLogs = JSON.parse(JSON.stringify(logs));
    newLogs[selectedLog].floors[index].monsters.splice(monsterIndex, 1);
    setLogs(newLogs);
    setEditMonster(false);
  }

  function sortUp() {
    let newLogs = JSON.parse(JSON.stringify(logs));
    let newEntry = newLogs[selectedLog].floors[index].monsters.splice(
      monsterIndex,
      1
    )[0];
    newLogs[selectedLog].floors[index].monsters.splice(
      monsterIndex - 1,
      0,
      newEntry
    );
    setLogs(newLogs);
    setEditMonster(false);
  }

  function sortDown() {
    let newLogs = JSON.parse(JSON.stringify(logs));
    let newEntry = newLogs[selectedLog].floors[index].monsters.splice(
      monsterIndex,
      1
    )[0];
    newLogs[selectedLog].floors[index].monsters.splice(
      monsterIndex + 1,
      0,
      newEntry
    );
    setLogs(newLogs);
    setEditMonster(false);
  }

  return (
    <div>
      {editMonster ? (
        <>
          <div>
            Editing monster <button onClick={saveMonster}>Done</button>{" "}
            <button onClick={deleteMonster}>Delete</button>
            {monsterIndex !== 0 && <button onClick={sortUp}>Move Up</button>}
            {monsterIndex !==
              logs[selectedLog].floors[index].monsters.length - 1 && (
              <button onClick={sortDown}>Move Down</button>
            )}
          </div>
          <div style={{ marginLeft: "10px" }}>
            <div>
              Name:{" "}
              <input
                value={
                  logs[selectedLog].floors[index].monsters[monsterIndex].name
                }
                onChange={(event) => editName(event)}
              />
            </div>
            <div>
              Threat:{" "}
              <select
                value={
                  logs[selectedLog].floors[index].monsters[monsterIndex].threat
                }
                onChange={(event) => editThreat(event)}
              >
                <option value="low">Low</option>
                <option value="medium">Medium</option>
                <option value="high">High</option>
                <option value="max">Max</option>
              </select>
            </div>
            <div>
              Aggro Type:{" "}
              <select
                value={
                  logs[selectedLog].floors[index].monsters[monsterIndex].aggro
                    ? logs[selectedLog].floors[index].monsters[monsterIndex]
                        .aggro
                    : "default"
                }
                onChange={(event) => editAggro(event)}
              >
                <option value="default">Not Set</option>
                <option value="sight">Sight</option>
                <option value="sound">Sound</option>
                <option value="proximity">Proximity</option>
              </select>
            </div>
            <div>
              Patrol{" "}
              <input
                type="checkbox"
                checked={
                  logs[selectedLog].floors[index].monsters[monsterIndex]
                    .isPatrol
                    ? logs[selectedLog].floors[index].monsters[monsterIndex]
                        .isPatrol
                    : false
                }
                onChange={(event) => editPatrol(event)}
              />
            </div>
            <div>
              <div>
                Abilities <button onClick={addAbility}>Add</button>
              </div>
              <div style={{ marginLeft: "10px" }}>
                {logs[selectedLog].floors[index].monsters[
                  monsterIndex
                ].abilities.map((data, index) => (
                  <div key={index}>
                    Name{" "}
                    <input
                      value={data.name}
                      onChange={(event) => setAbilityName(event, index)}
                    />{" "}
                    Description{" "}
                    <input
                      value={data.description}
                      onChange={(event) => setAbilityDescription(event, index)}
                    />
                    <button
                      style={{ marginLeft: "10px" }}
                      onClick={() => deleteAbility(index)}
                    >
                      Delete
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="mouseover">
          <img
            src={require(`../../Assets/DeepDungeon/Threat/${logs[selectedLog].floors[index].monsters[monsterIndex].threat}.png`)}
            style={{ width: "20px", marginRight: "5px" }}
          />
          {logs[selectedLog].floors[index].monsters[monsterIndex].name}{" "}
          <button onClick={() => setEditMonster(true)} className="hidden">
            Edit
          </button>
        </div>
      )}
    </div>
  );
}

import "../main.css";

export default function Footer({ colorTheme }) {
  return (
    <footer className={colorTheme === "light" ? "themeLight" : "themeDark2"}>
      <p>FINAL FANTASY XIV © SQUARE ENIX CO., LTD.</p>
      <p>
        FINAL FANTASY is a registered trademark of Square Enix Holdings Co.,
        Ltd.
      </p>
    </footer>
  );
}

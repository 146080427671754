import "./App.css";
import config from "./config";
import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Components/Home";
import Navbar from "./Components/Navbar";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import AdminCraftingLog from "./Components/Admin/AdminCraftingLog";
import AdminDeepDungeon from "./Components/Admin/AdminDeepDungeon";
import CraftingLog from "./Components/CraftingLog";
import DeepDungeon from "./Components/DeepDungeon";
import Item from "./Components/Item";
import Admin from "./Components/Admin/Admin";
import Login from "./Components/Login";
import Register from "./Components/Register";
import Speech from "./Components/Speech";

function App() {
  const [colorTheme, setColorTheme] = useState();
  const [loggedIn, setLoggedIn] = useState("loading");

  useEffect(() => {
    let storedColorTheme = localStorage.getItem("colorTheme");
    if (!storedColorTheme) {
      localStorage.setItem("colorTheme", "dark");
      setColorTheme("dark");
    } else {
      setColorTheme(storedColorTheme);
    }
  }, []);

  useEffect(() => {
    if (colorTheme === "light") {
      document.body.className = "themeLight";
      localStorage.setItem("colorTheme", "light");
    } else if (colorTheme === "dark") {
      document.body.className = "themeDark";
      localStorage.setItem("colorTheme", "dark");
    }
  }, [colorTheme]);

  useEffect(() => {
    async function verifyLogin() {
      const response = await fetch(`${config.BACKEND_URL}/logincheck`, {
        method: "POST",
        credentials: "include",
        cache: "no-store",
      });
      if (response.ok) {
        setLoggedIn("loggedIn");
      } else {
        setLoggedIn("notLoggedIn");
      }
    }
    verifyLogin();
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        <Header
          colorTheme={colorTheme}
          setColorTheme={setColorTheme}
          loggedIn={loggedIn}
          setLoggedIn={setLoggedIn}
        />
        <Navbar colorTheme={colorTheme} />
        <div className="mainContent">
          <Routes>
            <Route index element={<Home colorTheme={colorTheme} />} />
            <Route
              path="/login"
              element={
                <Login
                  colorTheme={colorTheme}
                  loggedIn={loggedIn}
                  setLoggedIn={setLoggedIn}
                />
              }
            />
            <Route
              path="/register"
              element={<Register colorTheme={colorTheme} />}
            />
            <Route
              path="/admin"
              element={<Admin colorTheme={colorTheme} loggedIn={loggedIn} />}
            />
            <Route
              path="/admin/logs/crafting"
              element={<AdminCraftingLog loggedIn={loggedIn} />}
            />
            <Route
              path="/admin/logs/deepdungeon"
              element={<AdminDeepDungeon loggedIn={loggedIn} />}
            />
            <Route path="/logs/crafting" element={<CraftingLog />} />
            <Route path="/logs/deepdungeon" element={<DeepDungeon />} />
            <Route
              path="/logs/deepdungeon/:dungeonid"
              element={<DeepDungeon />}
            />
            <Route
              path="/logs/deepdungeon/:dungeonid/:dungeonfloorset"
              element={<DeepDungeon />}
            />
            <Route path="/item/:paramitemname" element={<Item />} />
            <Route path="/speech" element={<Speech />} />
          </Routes>
          <Footer colorTheme={colorTheme} />
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;

import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import config from "../../config";

export default function Admin({ colorTheme, loggedIn }) {
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    document.title = "XIV Library | Admin Panel";
  }, []);

  useEffect(() => {
    if (loggedIn === "loggedIn") {
      async function adminCheck() {
        const response = await fetch(`${config.BACKEND_URL}/admin/verify`, {
          method: "POST",
          credentials: "include",
        });
        if (response.ok) {
          setIsAdmin(true);
        } else {
          navigate("/");
        }
      }
      adminCheck();
    } else if (loggedIn === "notLoggedIn") navigate("/login");
  }, [loggedIn]);

  if (!isAdmin) return "";
  else
    return (
      <div
        className={`homeContainer ${
          colorTheme === "light" ? "themeLight" : "themeDark2"
        }`}
      >
        <div
          style={{
            fontFamily: "trajan",
            textAlign: "center",
            fontSize: "30px",
            fontWeight: "bold",
            marginBottom: "15px",
          }}
        >
          Admin Panel
        </div>
        <div className="homeCategory">Logs</div>
        <div className="homeEntryContainer">
          <Link to="/admin/logs/crafting" className="homeEntry">
            <img src={require("../../Assets/Navbar/crafting.png")} />
            <p>Crafting Log</p>
          </Link>
          <Link to="#" className="homeEntry homeUnderConstruction">
            <img src={require("../../Assets/Navbar/gathering.png")} />
            <p>
              <s>Gathering Log</s>
            </p>
          </Link>
          <Link to="#" className="homeEntry homeUnderConstruction">
            <img src={require("../../Assets/Navbar/fishing.png")} />
            <p>
              <s>Fishing Log</s>
            </p>
          </Link>
          <Link to="#" className="homeEntry homeUnderConstruction">
            <img src={require("../../Assets/Navbar/sightseeing.png")} />
            <p>
              <s>Sightseeing Log</s>
            </p>
          </Link>
          <Link to="/admin/logs/deepdungeon" className="homeEntry">
            <img src={require("../../Assets/Navbar/deep_dungeon.png")} />
            <p>Deep Dungeon Log</p>
          </Link>
        </div>
        <div className="homeCategory">Collections</div>
        <div className="homeEntryContainer">
          <Link to="#" className="homeEntry homeUnderConstruction">
            <img src={require("../../Assets/Navbar/achievement.png")} />
            <p>
              <s>Achievements</s>
            </p>
          </Link>
          <Link to="#" className="homeEntry homeUnderConstruction">
            <img src={require("../../Assets/Navbar/mount.png")} />
            <p>
              <s>Mounts</s>
            </p>
          </Link>
          <Link to="#" className="homeEntry homeUnderConstruction">
            <img src={require("../../Assets/Navbar/minion.png")} />
            <p>
              <s>Minions</s>
            </p>
          </Link>
          <Link to="#" className="homeEntry homeUnderConstruction">
            <img src={require("../../Assets/Navbar/emote.png")} />
            <p>
              <s>Emotes</s>
            </p>
          </Link>
          <Link to="#" className="homeEntry homeUnderConstruction">
            <img src={require("../../Assets/Navbar/hairstyle.png")} />
            <p>
              <s>Hairstyles</s>
            </p>
          </Link>
          <Link to="#" className="homeEntry homeUnderConstruction">
            <img src={require("../../Assets/Navbar/orchestrion.png")} />
            <p>
              <s>Orchestrion Rolls</s>
            </p>
          </Link>
          <Link to="#" className="homeEntry homeUnderConstruction">
            <img src={require("../../Assets/Navbar/fashion.png")} />
            <p>
              <s>Fashion Accessories</s>
            </p>
          </Link>
          <Link to="#" className="homeEntry homeUnderConstruction">
            <img src={require("../../Assets/Navbar/barding.png")} />
            <p>
              <s>Chocobo Bardings</s>
            </p>
          </Link>
          <Link to="#" className="homeEntry homeUnderConstruction">
            <img src={require("../../Assets/Navbar/card.png")} />
            <p>
              <s>Triple Triad Cards</s>
            </p>
          </Link>
          <Link to="#" className="homeEntry homeUnderConstruction">
            <img src={require("../../Assets/Navbar/framer.png")} />
            <p>
              <s>Framer's Kits</s>
            </p>
          </Link>
          <Link to="#" className="homeEntry homeUnderConstruction">
            <img src={require("../../Assets/Navbar/relic.png")} />
            <p>
              <s>Relic Weapons / Tools</s>
            </p>
          </Link>
          <Link to="#" className="homeEntry homeUnderConstruction">
            <img src={require("../../Assets/Navbar/blue_mage.png")} />
            <p>
              <s>Blue Mage Spells</s>
            </p>
          </Link>
        </div>
        <div className="homeCategory">Sets</div>
        <div className="homeEntryContainer">
          <Link to="#" className="homeEntry homeUnderConstruction">
            <img src={require("../../Assets/Navbar/gear_set.png")} />
            <p>
              <s>Gear Sets</s>
            </p>
          </Link>
        </div>
      </div>
    );
}
